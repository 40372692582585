import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import { Router } from 'react-router-dom';
import store from './store'
import history from './history'

import Amplify from "aws-amplify";

React.icons = icons;

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    }
});

ReactDOM.render(
  <Provider store={store}>
      <Router history={history}>
        <App history={history}/>
      </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
