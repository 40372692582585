import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import useForm from '../../hooks/useForm';
import ChangeTempPasswordForm from '../../components/ChangeTempPasswordForm';
import fourfortylogo from '../../assets/icons/4forty-white.svg';
import {signIn} from '../../actions/auth';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import {Link} from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const [firstLogin, setFirstLogin] = useState(false);
  const [user, setUser] = useState(false);

  const {
    values,
    handleFieldChange,
    loading, setLoading,
    error, setError,
    errorMessage, setErrorMessage
  } = useForm({email: '', password: ''});

  const handleLogin = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);
    setErrorMessage('');

    dispatch(signIn({
      email: values.email,
      password: values.password,
    })).then((result) => {
      if (result.status === 'first_login') {
        setFirstLogin(true);
        setUser(result.data);
      }

      setLoading(false);
    }).catch((err) => {
      setError(true);
      setErrorMessage(err.message);
      setLoading(false);
    });
  };

  return (
    <div className="app d-flex flex-row align-items-center justify-content-center min-vh-100">
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  {
                    firstLogin ? (
                      <ChangeTempPasswordForm user={user}/>
                    ) : (
                      <Form onSubmit={handleLogin}>
                        <h1>Login</h1>

                        <p className="text-muted">Sign in to your account</p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="cil-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" id="email" name="email" placeholder="Email" autoComplete="email"
                                 required onChange={handleFieldChange}/>
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="cil-lock-locked"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="password" id="password" name="password" placeholder="Password"
                                 autoComplete="current-password" required onChange={handleFieldChange}/>
                        </InputGroup>

                        {
                          loading ? (
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : <Row className="mb-4">
                            <Col xs="6">
                              <Button color="primary" className="px-4">Login</Button>
                            </Col>
                            <Col xs="6" className="text-right">
                              <Link to={"/forgotpassword"}>
                                <a color="link" className="px-0">Forgot password?</a>
                              </Link>
                            </Col>
                          </Row>
                        }

                        {
                          error && (
                            <Row>
                              <Col xs="12">
                                <div className="alert alert-danger" role="alert">
                                  {errorMessage}
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                      </Form>
                    )
                  }
                </CardBody>
              </Card>
              <Card className="text-white bg-primary py-5 d-md-down-none" style={{width: 44 + '%'}}>
                <CardBody className="text-center d-flex align-items-center">
                  <div>
                    <img src={fourfortylogo} alt={'4forty Brand Logo'} className="sidebar__brand w-100"/>
                  </div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
