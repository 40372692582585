import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {updateEntity} from '../../reducers/environmentReducer';
import {useForm} from "react-hook-form";
import {
    CFormGroup,
    CLabel
} from "@coreui/react";

const EnvironmentSettingsUpdateForm = ({id}) => {
    const dispatch = useDispatch();

    const currentOrganisation = useSelector(state => state.context.currentOrganisation);
    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const loading = useSelector(state => state.environment.loading);
    const updating = useSelector(state => state.environment.updating);
    const updateSuccess = useSelector(state => state.environment.updateSuccess);
    const errorMessage = useSelector(state => state.environment.errorMessage);

    // // if event changes reload form
    useEffect(() => {
        form.reset({
            ...currentEnvironment
        });
    }, [currentEnvironment]);

    // if successful save redirect back to events
    useEffect(() => {
        if (updateSuccess) {

        }
    }, [updateSuccess]);

    const form = useForm({
        defaultValues: currentEnvironment
    });

    const watchDedicatedMuxAccount = form.watch("dedicatedMuxAccount");

    const saveEntity = (formData) => {
        const settingsData = {
            "organisationId": currentOrganisation.id,
            "name": formData.name,
            "dedicatedMuxAccount": formData.dedicatedMuxAccount,
            "muxTokenId": formData.muxTokenId,
            "muxTokenSecret": formData.muxTokenSecret,
            "muxSigningKey": formData.muxSigningKey,
            "muxPrivateKey": formData.muxPrivateKey,
        };

        settingsData.id = currentEnvironment.id;
        dispatch(updateEntity(settingsData));
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <form onSubmit={form.handleSubmit(saveEntity)}>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header"><strong>Environment Settings</strong></div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <CFormGroup>
                                                    <CLabel htmlFor="name">Name</CLabel>
                                                    <input type="text" className="form-control" name="name" ref={form.register} />
                                                </CFormGroup>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check">
                                                <CFormGroup>
                                                    <input type="checkbox" className="form-check-input" name="dedicatedMuxAccount" ref={form.register} />
                                                    <CLabel htmlFor="dedicatedMuxAccount" className="form-check-label">Dedicated Mux Account</CLabel>
                                                </CFormGroup>
                                            </div>
                                        </div>
                                    </div>

                                    {watchDedicatedMuxAccount && (
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <CFormGroup>
                                                            <CLabel htmlFor="muxTokenId">Mux Token Id</CLabel>
                                                            <input type="text" className="form-control" name="muxTokenId" ref={form.register} />
                                                        </CFormGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <CFormGroup>
                                                            <CLabel htmlFor="muxTokenSecret">Mux Token Secret</CLabel>
                                                            <input type="text" className="form-control" name="muxTokenSecret" ref={form.register} />
                                                        </CFormGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <CFormGroup>
                                                            <CLabel htmlFor="muxSigningKey">Mux Url Signing Key Id</CLabel>
                                                            <input type="text" className="form-control" name="muxSigningKey" ref={form.register} />
                                                        </CFormGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <CFormGroup>
                                                            <CLabel htmlFor="muxPrivateKey">Mux Url Signing Private Key</CLabel>
                                                            <textarea rows="30" className="form-control" name="muxPrivateKey" ref={form.register} />
                                                        </CFormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className={`col-md-2`}>
                            <button type="submit" className="btn btn-primary d-flex align-items-center"
                                    disabled={updating}>
                                <span className="cil-check-alt btn-icon mr-2"></span>
                                Save settings
                            </button>
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage.message}</div>
                    )}
                </form>
            )}
        </>
    )
};

export default EnvironmentSettingsUpdateForm;
