import {useDispatch} from "react-redux";
import useForm from "../hooks/useForm";
import {forceChangePassword} from "../actions/auth";
import {
  Button,
  Col,
  Form, Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import React from "react";

const ChangeTempPasswordForm = ({user}) => {
  const dispatch = useDispatch();

  const {
    values,
    handleFieldChange,
    loading, setLoading,
    error, setError,
    errorMessage, setErrorMessage
  } = useForm({newPassword: '', repeatPassword: ''});

  const handlePasswordChange = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);
    setErrorMessage('');

    if (values.newPassword !== values.repeatPassword) {
      setLoading(false);
      setError(true);
      setErrorMessage('Passwords do not match');
      return;
    }

    dispatch(forceChangePassword({
      user: user,
      newPassword: values.newPassword,
    })).then((result) => {
      setLoading(false);
    }).catch((err) => {
      setError(true);
      setErrorMessage(err.message);
      setLoading(false);
    });
  };

  return (
    <Form onSubmit={handlePasswordChange}>
      <h1>Change password</h1>

      <p className="text-muted">Looks like this is your first login, lets update your password</p>

      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="cil-lock-locked"></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input type="password" id="newPassword" name="newPassword" placeholder="New password" required onChange={handleFieldChange}/>
      </InputGroup>

      <InputGroup className="mb-4">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="cil-lock-locked"></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input type="password" id="repeatPassword" name="repeatPassword" placeholder="Confirm new password" required onChange={handleFieldChange}/>
      </InputGroup>

      {
        loading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : <Row className="mb-4">
          <Col xs="6">
            <Button color="primary" className="px-4">Update</Button>
          </Col>
        </Row>
      }

      {
        error && (
          <Row>
            <Col xs="12">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </Col>
          </Row>
        )
      }
    </Form>
  );
};

export default ChangeTempPasswordForm;