import React from 'react';
import formConfig from "../../config/formConfig.json";
import {CFormGroup, CLabel} from "@coreui/react";
import TextArea from "../common/TextArea";
import TextField from "../common/TextField";
import {CardHeader, CardTitle} from "reactstrap";
import {CCard,CCardHeader,CCardTitle,CCardBody} from "@coreui/react";
import FileField from "../common/FileField";

const EventUpdateFormMeta = ({form}) => {

    return <>

        <h3 className="py-2">Metadata</h3>
        { formConfig.config.fields?.metaDescription?.show &&
            <TextArea
                name="metaDescription"
                config={formConfig.config.fields?.metaDescription}
                form={form}
                />

        }
        {/*<CFormGroup>*/}
        {/*    <CLabel htmlFor="hero">Twitter Preview</CLabel>*/}
        {/*    <CInputFile id="hero"/>*/}
        {/*    <CFormText className="help-block">Upload a preview image for Twitter posts</CFormText>*/}

        {/*    {event.custom && event.custom.twitter && (*/}
        {/*        <div className="mb-3">*/}
        {/*            <img src={event.custom.twitter} className="img-thumbnail w-25" />*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*</CFormGroup>*/}

        {/*<CFormGroup>*/}
        {/*    <CLabel htmlFor="hero">Facebook Preview</CLabel>*/}
        {/*    <CInputFile id="hero"/>*/}
        {/*    <CFormText className="help-block">Upload a preview image for Facebook posts</CFormText>*/}
        {/*    {event.custom && event.custom.facebook && (*/}
        {/*        <div className="mb-3">*/}
        {/*            <img src={event.custom.facebook} className="img-thumbnail w-25" />*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*</CFormGroup>*/}
       <CCard color="light">

            <CCardBody>
                <h4 className="mb-4"><span style={{position: 'relative', top: '3px'}} className="cib-twitter mr-2" />Twitter</h4>
                { formConfig.config.fields?.twitterTitle?.show &&
                <TextField
                    name="custom.twitterTitle"
                    form={form}
                    config={formConfig.config.fields?.twitterTitle}

                />
                }
                { formConfig.config.fields?.twitterDescription?.show &&
                <TextArea
                    name="custom.twitterDescription"
                    form={form}
                    config={formConfig.config.fields?.twitterDescription}

                />
                }
                { formConfig.config.fields?.twitter?.show &&
                    <FileField name="custom.twitter"
                               config={formConfig.config.fields?.twitter}
                               form={form}
                               accept="image/*"
                               type="image"
                    />
                }
            </CCardBody>
        </CCard>

        <CCard color="light">

            <CCardBody>
                <h4 className="mb-4"><span style={{position: 'relative', top: '3px'}} className="cib-facebook mr-2" />Facebook Opengraph</h4>
                { formConfig.config.fields?.facebookTitle?.show &&
                    <TextField
                        name="custom.facebookTitle"
                        form={form}
                        config={formConfig.config.fields?.facebookTitle}
                    />
                }
                { formConfig.config.fields?.facebookDescription?.show &&
                    <TextArea
                        name="custom.facebookDescription"
                        config={formConfig.config.fields?.facebookDescription}
                        form={form}
                    />
                }
                { formConfig.config.fields?.facebook?.show &&
                    <FileField name="custom.facebook"
                               config={formConfig.config.fields?.facebook}
                               form={form}
                               accept="image/*"
                               type="image"
                    />
                }
            </CCardBody>
        </CCard>

    </>

}

export default EventUpdateFormMeta;
