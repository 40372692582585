import api from "../api";

import {REQUEST, SUCCESS, FAILURE} from './action-type.util';

export const ACTION_TYPES = {
    FETCH_ORGANISATION_LIST: 'organisation/FETCH_ORGANISATION_LIST',
    FETCH_ORGANISATION: 'organisation/FETCH_ORGANISATION',
    CREATE_ORGANISATION: 'organisation/CREATE_ORGANISATION',
    UPDATE_ORGANISATION: 'organisation/UPDATE_ORGANISATION',
    DELETE_ORGANISATION: 'organisation/DELETE_ORGANISATION',
    RESET: 'organisation/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: {},
    updating: false,
    updateSuccess: false,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_ORGANISATION_LIST):
        case REQUEST(ACTION_TYPES.FETCH_ORGANISATION):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_ORGANISATION):
        case REQUEST(ACTION_TYPES.UPDATE_ORGANISATION):
        case REQUEST(ACTION_TYPES.DELETE_ORGANISATION):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_ORGANISATION_LIST):
        case FAILURE(ACTION_TYPES.FETCH_ORGANISATION):
        case FAILURE(ACTION_TYPES.CREATE_ORGANISATION):
        case FAILURE(ACTION_TYPES.UPDATE_ORGANISATION):
        case FAILURE(ACTION_TYPES.DELETE_ORGANISATION):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_ORGANISATION_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_ORGANISATION):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_ORGANISATION):
        case SUCCESS(ACTION_TYPES.UPDATE_ORGANISATION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ORGANISATION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = '/system/organisations';

// Actions
export const getEntities = (page, size, sort) => ({
    type: ACTION_TYPES.FETCH_ORGANISATION_LIST,
    payload: api.instance.get(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity = id => ({
    type: ACTION_TYPES.FETCH_ORGANISATION,
    payload: api.instance.get(`${apiUrl}/${id}`),
});

export const createEntity = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_ORGANISATION,
        payload: api.instance.post(`${apiUrl}`, entity),
    });

    dispatch(getEntities());
    return result;
};

export const updateEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.UPDATE_ORGANISATION,
        payload: api.instance.put(`${apiUrl}/${entity.id}`, entity),
    });
};

export const deleteEntity = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_ORGANISATION,
        payload: api.instance.delete(`${apiUrl}/${id}`),
    });

    dispatch(getEntities());
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
