import React, {useEffect, useState} from "react";
import VideojsPlayer from "../video/VideojsPlayer";
import SocketIO from "../../api/sockets";
import {Badge} from "react-bootstrap";

const Player = ({streamPlaybackId, dataStatus}) => {

    //Set up the socket
    const socketIO = SocketIO();
    const socket = socketIO.getSocketInstance();

    const src = `https://stream.mux.com/${streamPlaybackId}.m3u8?timestamp=${new Date().getTime()}`;
    const [dataConnected, setDataConnected] = useState(dataStatus === 'on');

    useEffect(() => {
        socket.emit('admin-subscribe', {
            token: socketIO.getAuthorization()
        });

        socket.on('data-on', () => {
            setDataConnected(true);
        });

        socket.on('data-off', () => {
            setDataConnected(false);
        });

        return function cleanup() {
            socket.emit('admin-unsubscribe', {
                token: socketIO.getAuthorization()
            });
        };

    }, [streamPlaybackId, dataStatus]);

    return (
        <div className="mb-3">
            {
                !dataConnected ?
                    <div style={{
                        height: 0,
                        paddingTop : '56.25%',
                        width: '100%',
                        backgroundColor: '#ebedef',
                        position : 'relative'
                    }}>
                        <p
                            className="h5"
                            style={{
                                position : "absolute",
                                top: '6px',
                                left: '8px',
                                fontWeight : '300'
                            }}
                        ><Badge variant="secondary">NOT CONNECTED</Badge></p>
                    </div>
                    :
                    <VideojsPlayer src={`${src}`}
                                   setVideoPlayerEnded={() => {
                                       console.log('Video player finished');
                                   }}/>

            }
        </div>
    )
};

export default React.memo(Player)
