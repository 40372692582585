import socketIOClient from "socket.io-client";

const socketBaseUrl = process.env.REACT_APP_SOCKET_BASE_URL;

let socket;
let authorization;

const SocketIO = () => {

    function createSocket() {
        return socketIOClient(socketBaseUrl);
    }

    return {
        getSocketInstance: function () {
            if (!socket) {
                socket = createSocket();
            }
            return socket;
        },

        //can remove, swap out for API key
        authorize(token) {
            authorization = token;
        },

        getAuthorization: function () {
            return authorization;
        }

    };

};

export default SocketIO;