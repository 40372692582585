import {MODAL_HYDRATE,MODAL_CANCEL,MODAL_CLOSE,MODAL_OPEN,MODAL_PROCEED, LOGOUT} from "../actions/types";

const INITIAL_STATE = {
    title : '',
    message : '',
    proceedButton : 'OK',
    cancelButton : 'Cancel',
    type : 'PRIMARY', //colour
    modalActionState : null, //PROCEED | CANCEL
    open : false
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case MODAL_HYDRATE:
            return {
                ...state,
                ...payload,
                modalActionState: null
            };
        case MODAL_OPEN:
            return {
                ...state,
                open : true
            };
        case MODAL_CLOSE:
            return {
                ...state,
                open : false
            };
        case MODAL_CANCEL:
            return {
                ...state,
                modalActionState : 'CANCEL',
                open : false
            };
        case MODAL_PROCEED:
            return {
                ...state,
                modalActionState : 'PROCEED',
                open : false
            };
        case LOGOUT:
            return INITIAL_STATE;
        default:
            return state
    }
}
