import React from "react";
import dayjs from 'dayjs';

import {
    CDataTable,
    CBadge,
    CButton,
} from '@coreui/react'
import {contextAwareUrl, displayDateTime} from "../../helpers";

const EventList = ({eventData, eventList}) => {
    const fields = [
        {key: 'id', _style: {width: '25%'}},
        {key: 'title', _style: {width: '25%'}},
        {key: 'start', _style: {width: '25%'}},
        {key: 'status', _style: {width: '25%'}},
        {
            key: 'manage_link',
            label: '',
            _style: {width: '1%'},
            sorter: false,
            filter: false
        }
    ];

    const getBadge = (status) => {
        switch (status) {
            case 'pending':
                return 'info';
            case 'open':
                return 'success';
            case 'live':
                return 'danger';
            case 'live_interrupted':
                return 'warning';
            case 'vod':
                return 'primary';
            case 'closed':
                return 'dark';
            default:
                return 'light'
        }
    };

    const getBadgeText = (status) => {
        switch (status) {
            case 'pending':
                return 'Pending';
            case 'open':
                return 'Open';
            case 'live':
                return 'Live';
            case 'vod':
                return 'Video on demand';
            case 'closed':
                return 'Closed';
        }
    };

    const renderTitle = () => {
        switch (eventList) {
            case 'Upcoming':
                return 'Upcoming Events';
            case 'Past':
                return 'Past Events';
            default:
                return 'Events'
        }
    };

    return (
        <>
            <div className={'card shadow-none'}>
                <div className={'card-body table-responsive'}>
                    <h5 className={'card-title'}>{
                        renderTitle()
                    }</h5>
                    <CDataTable
                        items={eventData}
                        fields={fields}
                        columnFilter
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={50}
                        hover
                        sorter
                        pagination
                        striped
                        scopedSlots={{
                            'status':
                                (item) => (
                                    <td>
                                        <CBadge color={getBadge(item.streamStatus)}>
                                            {getBadgeText(item.streamStatus)}
                                        </CBadge>
                                    </td>
                                ),
                            'start':
                                (item) => (
                                    <td>
                                        {displayDateTime(item.start)}
                                    </td>
                                ),
                            'manage_link':
                                (item) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="primary"
                                                shape="rounded"
                                                size="sm"
                                                className="d-flex align-items-center"
                                                to={contextAwareUrl(`/events/${item.id}`)}>
                                                <span className={'cil-cog cil-button mr-2'}></span> Manage
                                            </CButton>
                                        </td>
                                    )
                                },
                        }}
                    />
                </div>
            </div>
        </>
    )
};

export default EventList;
