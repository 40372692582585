import React from 'react';
import {CModal,CModalHeader,CModalBody,CModalFooter,CButton,CModalTitle} from "@coreui/react";
import {useDispatch} from "react-redux";
import {useGetModal} from "../../hooks/useModal";
import {proceedModal, cancelModal} from "../../actions/modalActions";

const Modal = () => {

    const dispatch = useDispatch();

    const {
        cancelButton,
        message,
        open,
        proceedButton,
        title,
        type
    } = useGetModal();

    return (
        <CModal
            show={open}
            onClose={() => dispatch(cancelModal())}
            color={type.toLowerCase()}
        >
            <CModalHeader closeButton>
                <CModalTitle>{title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div dangerouslySetInnerHTML={{__html : message}} />
            </CModalBody>
            <CModalFooter>
                <CButton
                    color="secondary"
                    onClick={() => dispatch(cancelModal())}
                >{cancelButton}</CButton>{' '}
                <CButton
                    color={type.toLowerCase()}
                    onClick={() => dispatch(proceedModal())}
                >{proceedButton}</CButton>
            </CModalFooter>
        </CModal>
    )
};

export default Modal;