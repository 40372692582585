import React from "react";
import {CDataTable} from '@coreui/react'
import dayjs from "dayjs";

const displayDateTime = (dateTime) => {
    if (!dateTime) {
        return '';
    }

    return dayjs(dateTime).subtract(1, 'hour').format('HH:mm:ss - DD/MM/YYYY');
};

const EventAttendeeList = ({attendeeData}) => {

    let fields = [];

    fields.push(
        { key: 'viewerId', _style: { width: '30%'} },
        { key: 'joinedAt', _style: { width: '30%'} },
        { key: 'leftAt', _style: { width: '30%'} },
        { key: 'active', _style: { width: '10%'} }
    );

    return (
        <div className={'card shadow-none'}>
            <div className={'card-body table-responsive'}>
                <h3 className={'py-2'}>Attendees</h3>

                {attendeeData && attendeeData.length > 0 ? (
                    <CDataTable
                        items={attendeeData}
                        fields={fields}
                        columnFilter
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        sorter
                        pagination
                        scopedSlots = {{
                            'active':
                                (item) => {
                                    return (
                                        <td className={'text-center'}>
                                    <span className={
                                        item.active ?
                                            'cil-check-alt text-success mx-auto align-middle font-weight-bold'
                                            :
                                            'cil-x text-danger mx-auto align-middle font-weight-bold'
                                    }></span>
                                        </td>
                                    )
                                },
                            'joinedAt':
                                (item) => {
                                    return (
                                        <td className={'text-center'}>{displayDateTime(item.joinedAt)}</td>
                                    )
                                },
                            'leftAt':
                                (item) => {
                                    return (
                                        <td className={'text-center'}>{displayDateTime(item.leftAt)}</td>
                                    )
                                },
                        }}
                    />
                ) : (
                    <p>No attendees for this event.</p>
                )}

            </div>
        </div>
    )
};

export default EventAttendeeList;
