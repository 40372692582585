import axios from 'axios';
import {Auth} from 'aws-amplify';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class API {


  constructor(apiBase = apiBaseUrl, options = {}) {
    this.instance = axios.create({
      baseURL: apiBase,
      ...options
    });

    this.instance.interceptors.request.use((config) => {
      return Auth.currentSession()
          .then(session => {

            if (session.accessToken.jwtToken !== this.getToken()) {
              // User is logged in. Set auth header on all requests
              this.authorize(session.accessToken.jwtToken)
              console.log('API addInterceptor: ', session.accessToken.jwtToken);
            }

            return config
          })
          .catch((e) => {
            console.log(e);
            // No logged-in user: don't set auth header
            console.log('API addInterceptor is null: ', e);
            return config
          })
    });

    this.addCommonHeader('x-environment-key', '8442df38-af4f-4a53-a680-706231043936');

  }

  addCommonHeader(name, value) {
    this.instance.defaults.headers.common[name] = value;
  }

  authorize(token) {
    this.addCommonHeader('Authorization', token);
  }

  getToken() {
    return this.instance.defaults.headers.common["Authorization"]
  }

  getBaseUrl() {
    return this.instance.defaults.baseURL;
  }

  buildFileUrl(fileId) {
    return `${this.getBaseUrl()}/system/uploads/${fileId}/serve?token=${this.getToken()}`;
  }

  forbid() {
    delete this.instance.defaults.headers.common["Authorization"];
  }

}

export const requestThirdParty = (apiBase) => {

  const newRequest = new Request(apiBase, {
    transformRequest: [(data, headers) => {
      delete headers.common.Authorization;
      return data;
    }]
  });


  return newRequest;
};

export default new API();
