import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CTooltip
} from '@coreui/react'

import fourfortylogowhite from '../assets/icons/4forty-white.svg';
import {contextAwareUrl} from "../helpers";

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.layout.sidebarShow);

  const ContextAwareSidebarNavItem = (props) => {

    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const currentOrganisation = useSelector(state => state.context.currentOrganisation);

    const {to, ..._props} = props;

    const _to = `/org/${currentOrganisation.id}/env/${currentEnvironment.id}${to}`;

    return <CSidebarNavItem to={_to} {..._props} />

  };

  return (
    <CSidebar
      show={show}
      unfoldable
      onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none justify-content-start" to="/">
        <img src={fourfortylogowhite} alt={'4forty Logo'} />
      </CSidebarBrand>
      <CSidebarNav>

        <ContextAwareSidebarNavItem name='Dashboard' to={'/dashboard'} icon='cil-speedometer' />

        <ContextAwareSidebarNavItem name='Audience' to='/audience' icon='cil-smile' />

        {/*<CSidebarNavDropdown name='Users' icon='cil-people'>*/}
        {/*  <ContextAwareSidebarNavItem name='Administrators' to='/administrators' icon='cil-cog' />*/}
        {/*  <ContextAwareSidebarNavItem name='Audience' to='/audience' icon='cil-smile' />*/}
        {/*  <ContextAwareSidebarNavItem name='Accounts' to='/accounts' icon='cil-people' />*/}
        {/*</CSidebarNavDropdown>*/}

        {/*<CSidebarNavItem name='Ticket sales' to='/sales' icon='cil-money' />*/}

        {/*<CSidebarNavTitle>Audio</CSidebarNavTitle>*/}

        {/*<CTooltip content="Coming soon to 4Forty" offset="20" placement="top">*/}
        {/*  <CSidebarNavItem name='Upload' to='' icon='cil-money' className="cursor-not-allowed" />*/}
        {/*</CTooltip>*/}
        {/*<CTooltip content="Coming soon to 4Forty" offset="20" placement="top">*/}
        {/*  <CSidebarNavItem name='My tracks' to='' icon='cil-music-note' className="cursor-not-allowed" />*/}
        {/*</CTooltip>*/}

        <CSidebarNavTitle>Live</CSidebarNavTitle>
        <ContextAwareSidebarNavItem name='Schedule an event' to={'/events/new'} icon='cil-clock' />
        <ContextAwareSidebarNavItem name='Events' to={'/events'} icon='cil-calendar' />

        <CSidebarNavTitle>Environment</CSidebarNavTitle>
        <ContextAwareSidebarNavItem name='Settings' to={'/settings'} icon='cil-settings' />
        <ContextAwareSidebarNavItem name='Webhooks' to={'/webhooks'} icon='cil-link' />
        <ContextAwareSidebarNavItem name='Api Keys' to={'/apikeys'} icon='cib-open-access' />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
