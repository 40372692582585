import React from 'react'
import EventUpdateForm from '../../components/events/EventUpdateForm';
import { Link } from 'react-router-dom';
import {contextAwareUrl} from "../../helpers";

const EventUpdate = (props) => {

    const backUri = props.match.params.id ? `/events/${props.match.params.id}` : '/events';
    const backText = props.match.params.id ? 'Back to event' : 'Back to events';

    return (
    <>
        <Link to={contextAwareUrl(backUri)} className="btn btn-dark w-fit mb-3 d-flex align-items-center">
            <span className="cil-arrow-left btn-icon mr-2"></span> {backText}
        </Link>

        <EventUpdateForm id={props.match.params.id} />
    </>
    )
};

export default EventUpdate;
