import React from 'react';
import {CFormGroup, CLabel,CCard,CCardBody} from "@coreui/react";
import formConfig from "../../config/formConfig.json";
import {Controller} from "react-hook-form";
import TextEditor from "../../modules/TextEditor";


const Wysiwyg = ({form,config,name}) => {

    console.log('Wysiwyg name', name);

    return <CCard>
        <CCardBody>
        <CFormGroup>
            <h5 className="mb-3">{config?.label}</h5>
            {/*<Controller
                control={form.control}
                name={name}
                render={({onChange, value}) => (
                    <TextEditor
                        onChange={(_value) => onChange(_value)}
                        value={value || ''}
                    />
                )}
            />*/}
            <Controller
                control={form.control}
                name={name}
                render={({onChange, value}) => (
                    <TextEditor
                        onChange={(_value) => onChange(_value)}
                        value={value || ''}
                    />
                )}
            />
        </CFormGroup>
        </CCardBody>
    </CCard>

}

export default Wysiwyg;