import React from 'react';
import {CCard, CCardBody, CFormGroup} from "@coreui/react";
import {Controller} from "react-hook-form";
import FileUploader from "../FileUploader";


const FileField = ({form, config, name, accept, type}) => {
    return <CCard>
        <CCardBody>
            <h5 className="mb-3">{config.label}</h5>

            <CFormGroup>
                <Controller
                    control={form.control}
                    name={name}
                    render={({onChange, value}) => (
                        <>
                            <FileUploader
                                value={value}
                                onSave={(uploadId) => {
                                    onChange(uploadId)
                                }}
                                accept={accept}
                                type={type}
                            />
                        </>
                    )}
                />
            </CFormGroup>
        </CCardBody>
    </CCard>
};

export default FileField;