const transformAudienceList = (users) => {

    return users.map( user => {

        const {firstName, lastName, ...rest} = user;

        return {
            name : `${firstName} ${lastName}`,
            ...rest
        }
    });

};

export default transformAudienceList
