import React from "react";

import {
    CRow,
    CCol,
    CWidgetIcon,
    CWidgetProgressIcon
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

const Dashboard = () => {

  return (
    <>
        <CRow>
            <CCol>
                <CWidgetProgressIcon
                    header="0"
                    text="Audience Members"
                    color="info"
                >
                    <div className="h1 text-muted text-right mb-4">
                        <span className={'cil-people'}></span>
                    </div>
                </CWidgetProgressIcon>
            </CCol>
            <CCol>
                <CWidgetProgressIcon
                    header="0"
                    text="New Members"
                    color="success"
                >
                    <div className="h1 text-muted text-right mb-4">
                        <span className={'cil-user-plus'}></span>
                    </div>
                </CWidgetProgressIcon>
            </CCol>
            <CCol>
                <CWidgetProgressIcon
                    header="0"
                    text="Total Events"
                    color="success"
                >
                    <div className="h1 text-muted text-right mb-4">
                        <span className={'cil-stream'}></span>
                    </div>
                </CWidgetProgressIcon>
            </CCol>
            <CCol>
                <CWidgetProgressIcon
                    header="0"
                    text="Upcoming Events"
                    color="success"
                >
                    <div className="h1 text-muted text-right mb-4">
                        <span className={'cil-av-timer'}></span>
                    </div>
                </CWidgetProgressIcon>
            </CCol>
        </CRow>

        <CRow>
            <CCol col="12" sm="6">
                <CWidgetIcon
                    header="0"
                    text="Tickets Sold"
                    color="primary"
                >
                    <span className={'cil-basket'}></span>
                </CWidgetIcon>
            </CCol>
            <CCol col="12" sm="6">
                <CWidgetIcon
                    header="£0.00"
                    text="Income"
                    color="info"
                >
                    <span className={'cil-british-pound'}></span>
                </CWidgetIcon>
            </CCol>
        </CRow>
    </>
  )
};

export default Dashboard
