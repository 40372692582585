import React, { useState } from 'react';
import {
    CButton,
    CSelect,
    CCard,
    CCardBody,
    CCardHeader,
    CAlert,
    CBadge

} from '@coreui/react'
import api from "../../api";
import useModal from "../../hooks/useModal";

const EventDetailMessages = ({event}) => {

    const [testMode, setTestMode] = useState(true);
    const [successfulRecipients, setSuccessfulRecipients] = useState([]);
    const [failureMessage,setFailureMessage] = useState('');


    const handleChangeTestMode = (e) => {

        console.log(e);

        if(e.target.value === 'testOff') {
            setTestMode(false);
            console.log(e.target.value);
        } else if (e.target.value === 'testOn'){
            setTestMode(true);
            console.log(e.target.value);
        }

    };

    const callSendTestEmailApi = async () => {
        let emailEndPoint = `/system/events/${event.id}/send-reminder-email?test=true`
        if (!testMode) {
            emailEndPoint = `/system/events/${event.id}/send-reminder-email`
        }

        try {
            const testEmailResponse = await api.instance.get(emailEndPoint);
            console.log(testEmailResponse.data.users);
            if (testEmailResponse.data.success) {
                setSuccessfulRecipients(testEmailResponse.data.users)
                setFailureMessage('');
            } else {
                setFailureMessage(testEmailResponse.data.message);
                setSuccessfulRecipients([]);
            }
        } catch (e) {
            console.log(e.response);
            setSuccessfulRecipients([]);
            if (e.response.status === 500) {
                setFailureMessage(e.response.data.message);
            } else {
                setFailureMessage('There has been an error sending the email');
            }
        }
    }

    const [areYouSureModal] = useModal({
        cancelButton : 'Cancel send',
        message : 'This will send the reminder email to everyone that has purchased a ticket!',
        title : "Are you absolutely sure?",
        type : 'warning',
        proceedButton : 'Definitely send'
    })

    const handleSendEmail = async () => {

        if (!testMode) {
            areYouSureModal(callSendTestEmailApi);
        } else {
            await callSendTestEmailApi();
        }

    }

    return <>

        <h3 className="py-2">Messages</h3>

        <p>From here you can send a reminder email on the day of the event, with instructions of how to watch it.
            <br />Use test mode to ONLY send to those with admin logins.</p>

        <div className="row">
            <div className="col-4">
                <CSelect onChange={handleChangeTestMode}>
                    <option value="testOn" selected={true}>Send as test</option>
                    <option value="testOff" selected={false}>Send to actual audience</option>
                </CSelect>
            </div>
            <div className="col-6">
                <CButton
                    type="button"
                    color="primary"
                    onClick={handleSendEmail}
                >Send email</CButton>
            </div>
        </div>

        { successfulRecipients.length > 0 &&
            <CAlert color="success" className="mt-4">
                {successfulRecipients.length === 1 &&
                <p>{testMode ? 'A test reminder email' : 'The reminder email'} has successfully been sent to the following recipient:</p>}
                {successfulRecipients.length > 1 &&
                <p>{testMode ? 'A test reminder email' : 'The reminder email'} has successfully been sent to the following {successfulRecipients.length} recipients:</p>}
                <p>
                    {successfulRecipients.map((recipient) => {
                        return <><CBadge color="success">{recipient}</CBadge>{' '}</>
                    })}
                </p>
            </CAlert>

        }

        { failureMessage !== '' && <CAlert className="mt-4" color="danger">{failureMessage}</CAlert>}

    </>

}

export default EventDetailMessages;