import { applyMiddleware, compose, createStore } from 'redux'
import promiseMiddleware from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'
import reducers from './reducers'

const initialState = {};
const enhancers = [];

if (window && window.location && window.location.hostname === 'localhost') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(
            thunkMiddleware,
            promiseMiddleware
        ),
        ...enhancers
    ));
export default store
