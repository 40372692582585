import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
} from '@coreui/react'

import {getEntities} from '../../reducers/eventReducer';
import EventList from '../../components/events/EventList';
import {Link} from 'react-router-dom';
import {contextAwareUrl} from "../../helpers";

const Events = () => {
    const dispatch = useDispatch();
    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const events = useSelector(state => state.event.entities);
    const loading = useSelector(state => state.event.loading);
    const errorMessage = useSelector(state => state.event.errorMessage);

    const [upcomingEventData, setUpcomingEventData] = useState(null);
    const [pastEventData, setPastEventData] = useState(null);

    // initial load request events
    useEffect(() => {
        dispatch(getEntities(currentEnvironment.id));
    }, [currentEnvironment]); // reload if environment changes

    // when events are loaded split into groups
    useEffect(() => {
        const currentDate = new Date();

        if (events && events.length > 0) {
            setUpcomingEventData(events.filter(event => {
                return moment(event.start, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).add(1, 'd').toDate()
                    > currentDate;
            }));

            setPastEventData(events.filter(event => {
                return moment(event.start, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).add(1, 'd').toDate()
                    < currentDate;
            }));
        }
    }, [events]);

    return (
        <>
            <Link to={contextAwareUrl("/events/new")} className="btn btn-primary w-fit ml-auto mb-3 d-flex align-items-center">
                <span className="cil-plus btn-icon mr-2"></span> Schedule new event
            </Link>

            {events && events.length > 0 ? (
                <>
                    <CTabs activeTab="upcoming">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="upcoming">
                                    Upcoming events
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="past">
                                    Past events
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="upcoming">
                                <EventList eventData={upcomingEventData} eventList='Upcoming'/>
                            </CTabPane>
                            <CTabPane data-tab="past">
                                <EventList eventData={pastEventData} eventList='Past'/>
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </>
            ) : (
                <>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {errorMessage ? (
                                <div className="alert alert-danger">{errorMessage.message}</div>
                            ) : (
                                <div className="alert alert-warning">No Events found</div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
};

export default Events;
