const INITIAL_STATE = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false
};

export default (state = INITIAL_STATE, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return {...state, ...rest };
        default:
            return state
    }
}