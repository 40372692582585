import React from 'react'
import ReactQuill from 'react-quill'
import {
    CCard
} from '@coreui/react'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const TextEditor = ({name, value, onChange}) => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'link'],
            [{ 'list': 'ordered'}, {'list': 'bullet'}],
            [{ 'header': [1, 2, 3, false] }],
            [{ 'align': ''}, { 'align': 'center'}],
            ['clean']
        ]
    };

    return (
        <CCard>
            <ReactQuill name={name} value={value} modules={modules} onChange={onChange} />
        </CCard>
    )
};

export default TextEditor
