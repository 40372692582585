import React from 'react';
import {displayDateTime} from "../../helpers";
import {CardBody, InputGroup} from "reactstrap";
import api from "../../api";

const publicEventsSiteUrl = process.env.REACT_APP_PUBLIC_EVENTS_SITE_URL;

const EventDetailContent = ({event}) => {

    return <>
        <h3 className="py-2">Content</h3>
        <dl className="row">
            <dt className="col-sm-2">
                Starts
            </dt>
            <dd className="col-sm-10">
                <div className="mb-3">
                    {displayDateTime(event.start)}
                </div>
            </dd>
            <dt className="col-sm-2">
                Checkout Link
            </dt>
            <dd className="col-sm-10">
                <div className="mb-3">
                    <InputGroup>
                        <div className="form-control">
                            <code className="highlight-rouge">{`${publicEventsSiteUrl}/checkout/${event.id}`}</code>
                        </div>
                    </InputGroup>
                </div>
            </dd>
            {event.slug && (
                <>
                    <dt className="col-sm-2">
                        Slug
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            {event.slug}
                        </div>
                    </dd>
                </>
            )}
            {event.introduction && (
                <>
                    <dt className="col-sm-2">
                        Introduction
                    </dt>
                    <dd className="col-sm-10">
                        <div dangerouslySetInnerHTML={{__html: event.introduction}}/>
                    </dd>
                </>
            )}
            {event.description && (
                <>
                    <dt className="col-sm-2">
                        Description
                    </dt>
                    <dd className="col-sm-10">
                        <div dangerouslySetInnerHTML={{__html: event.description}}/>
                    </dd>
                </>
            )}
            {event.programmeFile && (
                <>
                    <dt className="col-sm-2">
                        Programme Pdf
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            <a href={api.buildFileUrl(event.programmeFile)} target="_blank">
                                <span>Download</span>
                            </a>
                        </div>
                    </dd>
                </>
            )}
            {event.bannerImage && (
                <>
                    <dt className="col-sm-2">
                        Hero
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            <img src={api.buildFileUrl(event.bannerImage)} className="img-thumbnail w-25" />
                        </div>
                    </dd>
                </>
            )}
            {event.thumbnailImage && (
                <>
                    <dt className="col-sm-2">
                        Thumbnail
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            <img src={api.buildFileUrl(event.thumbnailImage)} className="img-thumbnail w-25" />
                        </div>
                    </dd>
                </>
            )}
        </dl>


        <h4>Meta</h4>

        <dl className="row">
            {event.metaDescription && (
                <>
                    <dt className="col-sm-2">
                        Description
                    </dt>
                    <dd className="col-sm-10">
                        <p>{event.metaDescription}</p>
                    </dd>
                </>
            )}
            {event.custom && event.custom.twitter && (
                <>
                    <dt className="col-sm-2">
                        Twitter Preview
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            <img src={api.buildFileUrl(event.custom.twitter)} className="img-thumbnail w-25" />
                        </div>
                    </dd>
                </>
            )}
            {event.custom && event.custom.facebook && (
                <>
                    <dt className="col-sm-2">
                        Facebook Preview
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            <img src={api.buildFileUrl(event.custom.facebook)} className="img-thumbnail w-25" />
                        </div>
                    </dd>
                </>
            )}
        </dl>

        <h4>Information</h4>


        <dl className="row">
            {(event.custom && event.custom.people && event.custom.people.length > 0) && (
                <>
                    <dt className="col-sm-2">
                        People
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            {event.custom.people.map((person, index) => {
                                let itemText = `${person.name} - ${person.title}`;

                                return (
                                    <div key={`person-${index}`}>
                                        {itemText}
                                    </div>
                                )
                            })}
                        </div>
                    </dd>
                </>
            )}

            {(event.custom && event.custom.programme && event.custom.programme.length > 0) && (
                <>
                    <dt className="col-sm-2">
                        Programme
                    </dt>
                    <dd className="col-sm-10">
                        <div className="mb-3">
                            {event.custom.programme.map((item, index) => {
                                let itemText = `${item.composer} - ${item.title}`;

                                if (item.arranger) {
                                    itemText = itemText + `, ${item.arranger}`;
                                }

                                itemText = itemText + `[${item.length}]`;

                                return (
                                    <div key={`programme-item-${index}`}>
                                        {itemText}
                                    </div>
                                )
                            })}
                        </div>
                    </dd>
                </>
            )}
        </dl>

    </>

}

export default EventDetailContent;