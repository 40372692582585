import React from 'react'
import WebhookUpdateForm from '../../components/webhooks/WebhookUpdateForm';
import { Link } from 'react-router-dom';
import {contextAwareUrl} from "../../helpers";

const WebhookUpdate = (props) => {

    return (
        <>
            <Link to={contextAwareUrl('/webhooks')} className="btn btn-dark w-fit mb-3 d-flex align-items-center">
                <span className="cil-arrow-left btn-icon mr-2"></span> Back to webhooks
            </Link>

            <WebhookUpdateForm id={props.match.params.id} />
        </>
    )
};

export default WebhookUpdate;
