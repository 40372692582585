import React, {useEffect, useState} from "react";

import {
    CCard,
    CCardHeader,
    CDataTable,
    CBadge,
    CButton,
    CCollapse,
    CCardBody,
    CSwitch, CLabel, CTextarea, CFormGroup, CForm
} from '@coreui/react'
import api from "../../api";

import Select from 'react-select'

import DeleteModal from '../DeleteModal';
import {Link} from "react-router-dom";

const UserEvents = ({events, registeredEvents, toggleAccess, addEvents, removeEvent}) => {

    const [details, setDetails] = useState([]);
    const [combinedEvents, setCombinedEvents] = useState(null);

    const [availableEvents, setAvailableEvents] = useState(null);

    const [value, setValue] = React.useState([]);

    useEffect(() => {

        const _combinedEvents = events && registeredEvents && registeredEvents.map(rEvent => {
            return events.find(event => event.id === rEvent);
        });

        setCombinedEvents(_combinedEvents);

        const _availableEvents = events && registeredEvents && events.filter(event => {
            return !registeredEvents.includes(event.id)
        }).map(event => {
            return {value: event.id, label: event.title}
        });

        setAvailableEvents(_availableEvents);

        setValue([]);

        console.log('combined events: ', _combinedEvents);

    }, [registeredEvents, events]);

    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    };

    const fields = [
        {key: 'title', label: 'Event title'},
        {key: 'paymentMethod', label: 'Access method'},
        {key: 'paymentDate', label: 'Date added',},
        {
            key: 'remove',
            label: '',
            _style: {width: '1%'},
            sorter: false,
            filter: false
        },
        {
            key: 'refund',
            label: '',
            _style: {width: '1%'},
            sorter: false,
            filter: false
        }

        // {
        //     key: 'show_details',
        //     label: '',
        //     _style: {width: '1%'},
        //     sorter: false,
        //     filter: false
        // }
    ];

    return (
        <>
            <div className={'card  shadow-none'}>
                <div className={'card-body table-responsive'}>
                    <h5 className={'card-title'}>
                        Events
                    </h5>
                    { !!combinedEvents && !!combinedEvents[0] && <CDataTable
                        items={combinedEvents}
                        fields={fields}
                        columnFilter
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={50}
                        hover
                        striped
                        sorter
                        pagination
                        scopedSlots={{
                            'title':
                                (item) => (
                                    <td className="align-middle">
                                        <Link to={`/event/${item.id}`}>
                                            {item.title}
                                        </Link>
                                    </td>
                                ),
                            'paymentMethod':
                                (item) => (
                                    <td className="align-middle">
                                        Stripe
                                    </td>
                                ),
                            'paymentDate':
                                (item) => (
                                    <td className="align-middle">
                                        {`Saturday, 19th September, 2020`}
                                    </td>
                                ),
                            'refund':
                                (item, index) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="danger"
                                                shape="rounded"
                                                size="sm"
                                                className="d-flex align-items-center"
                                                onClick={() => removeEvent(item.id)}
                                            >
                                                <span class="cil-ban btn-icon mr-2"></span>
                                                Remove
                                            </CButton>
                                        </td>
                                    )
                                },
                            'remove':
                                (item, index) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="warning"
                                                shape="rounded"
                                                size="sm"
                                                className="d-flex align-items-center"
                                            >
                                                <span className="cil-credit-card btn-icon mr-2"></span>
                                                Refund
                                            </CButton>
                                        </td>
                                    )
                                },
                            'show_details':
                                (item, index) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="primary"variant="outline"
                                                shape="square"
                                                size="sm"
                                                onClick={() => {
                                                    toggleDetails(index)
                                                }}
                                            >
                                                {details.includes(index) ? 'Hide' : 'Show'}
                                            </CButton>
                                        </td>
                                    )
                                },
                        }}
                    />
                    }
                </div>
            </div>
                <CCard className={'shadow-none'}>
                <CCardHeader>
                    Add event to user{' '}
                </CCardHeader>
                <CCardBody>
                    <Select
                        name="form-field-name2"
                        value={value}
                        options={availableEvents}
                        onChange={setValue}
                        isMulti
                    />
                </CCardBody>
                { value.length > 0 &&
                    <CCardBody>
                        <CButton
                            color="primary"
                            shape="rounded"
                            size="sm"
                            className="d-flex align-items-center"
                            onClick={() => {
                                addEvents(value)
                            }}
                        >
                            <span class="cil-plus btn-icon mr-2"></span>
                            { value.length > 1 ? `Add events` : `Add event` }
                        </CButton>
                    </CCardBody>
                }


            </CCard>
        </>
    )
};

export default UserEvents;
