import React, {useState} from 'react';

import {
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CInputGroup,
    CButtonGroup,
    CFormGroup
} from "@coreui/react";
import api from "../api";
import {useSelector} from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

const FileUploader = ({value, onSave, accept, type}) => {

    const [modal, setModal] = useState(false);
    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const [upload, setUpload] = useState(null);

    // specify upload params and url for your files
    const getUploadParams = ({ file, meta }) => {
        const body = new FormData();
        body.append('upload', file);
        body.append('name', 'test');

        return {
            url: `${api.getBaseUrl()}/system/uploads?environmentId=${currentEnvironment.id}`,
            headers: {"Authorization": `${api.getToken()}`},
            body
        };
    };

    const handleChangeStatus = ({ meta, remove, xhr }, status) => {
        if (status === 'done') {
            let upload = JSON.parse(xhr.response);

            onSave(upload.id);
            setModal(false);
        }
    };

    const close = (e)=>{
        setModal(false);
    };

    const open = ()=>{
        setModal(true);
    };

    const onDelete = () => {
        onSave(null);
    };

    return (
        <>
            <CModal show={modal}>
                <CModalHeader>File upload</CModalHeader>

                <CModalBody>
                    <Dropzone
                        inputContent={'Drag File or Click to Browse'}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        accept={accept}
                        styles={{
                            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                            inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                        }}
                    />
                </CModalBody>

                <CModalFooter>
                    <CButton color="secondary" onClick={close}>Cancel</CButton>
                </CModalFooter>
            </CModal>

            <CInputGroup>
                {value &&
                    <div className="mb-3">
                        { type === "image" ? (
                            <a href={api.buildFileUrl(value)} target="_blank">
                                <img src={api.buildFileUrl(value)} className="img-thumbnail" />
                            </a>
                        ) : (
                            <a href={api.buildFileUrl(value)} target="_blank">
                                <span>Download</span>
                            </a>
                        )}
                    </div>
                }
            </CInputGroup>

            <CButtonGroup>
                <CButton onClick={open} color="info">{ value ? (<>Change</>) : (<>Upload</>) }</CButton>

                { value && (
                    <CButton onClick={onDelete} color="danger"><span className="cil-trash btn-icon" /></CButton>
                )}
            </CButtonGroup>
        </>
    )
};

export default FileUploader;
