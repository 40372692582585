import history from "../history";

//HAD TO USE AS DAYJS HAS BUG FOR TIMEZONES
import moment from 'moment-timezone';

export function encodeBase64(str) {
    return btoa(str);
}

export function decodeBase64(str) {
    if(str) {
        return atob(str);
    }
}

// TODO: need to sort the subtracting 1, if we dont do this time is out by an hour
export function displayDateTime(dateTime) {
    return moment.utc(dateTime).tz("Europe/London").format('dddd, D MMMM YYYY | h:mma z');
}

export function displayDateTimeInContext(dateTime) {
    return moment.utc(dateTime).tz("Europe/London").format('Do MMM YY [at] h:mma z');
}

export function formatDateTimeForInput(dateTime) {
    return moment.utc(dateTime).tz("Europe/London").format('YYYY-MM-DDTHH:mm')
}

export function contextAwareUrl(url) {
    console.log('context aware: ', history.location.pathname.split('/').splice(0,5).join().replaceAll(',','/'))
    return `${history.location.pathname.split('/').splice(0,5).join().replaceAll(',','/')}${url}`
}
