import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CToggler,
  CNavbarNav,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import avatar from '../assets/icons/avatar.jpg'

import fourfortylogoblue from '../assets/icons/4forty-blue.svg';

// routes config - needed for breadcrumbs
//import routes from '../routes'
import api from "../api";

import history from '../history';
import {isAuthenticated, signOut} from '../actions/auth';
import {setCurrentEnvironment, setCurrentOrganisation} from '../reducers/contextReducer';

const TheHeader = () => {
  const dispatch = useDispatch();

  const organisations = useSelector(state => state.organisation.entities);
  const currentOrganisation = useSelector(state => state.context.currentOrganisation);
  const currentEnvironment = useSelector(state => state.context.currentEnvironment);

  //const asideShow = useSelector(state => state.layout.asideShow);
  const darkMode = useSelector(state => state.layout.darkMode);
  const sidebarShow = useSelector(state => state.layout.sidebarShow);

  // if either change then just send back to dashboard to reload: TODO: this would be better if the current page just updated
  // useEffect(() => {
  //   history.push('/dashboard');
  // }, [currentOrganisation, currentEnvironment]);

  const toggleOrganisation = (organisation) => {

    console.log(organisation);

    const orgSlug = organisation.id.toString().toLowerCase();

    const rawSlug = history.location.pathname.split('/').slice(3).join().replaceAll(',','/');

    console.log(`/org/${orgSlug}/${rawSlug}`);

    history.push(`/org/${orgSlug}/${rawSlug}`);

    // history.push(`/org/${orgSlug}/environments/${envSlug}/${rawSlug}`);

    // dispatch(setCurrentOrganisation(organisation));
  };

  const toggleEnvironment = (environment) => {

    const envSlug = environment.id.toString().toLowerCase();
    const rawSlug = history.location.pathname.split('/').slice(5).join().replaceAll(',','/');

    const orgSlug = history.location.pathname.split('/')[2];

    console.log('PUSHED TO: ', `/org/${orgSlug}/env/${envSlug}/${rawSlug}`)

    history.push(`/org/${orgSlug}/env/${envSlug}/${rawSlug}`);

  };

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
    dispatch({type: 'set', sidebarShow: val})
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
    dispatch({type: 'set', sidebarShow: val})
  };

  const handleLogout = () => {
    dispatch(signOut());
  };

  const getToken = (e) => {
    prompt('Token: ', api.getToken());
  };

  //temp until user orgs are set up
  const config = {
    organisationName : currentOrganisation.name
  };

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <img src={fourfortylogoblue} alt={'4forty Logo'} />
      </CHeaderBrand>

      <CNavbarNav className="d-flex justify-content-center d-md-down-none">
        {organisations.length > 1 ? (
            <CDropdown className="m-0">
              <CDropdownToggle caret color="light">
                {currentOrganisation.name}
              </CDropdownToggle>
              <CDropdownMenu>
                {
                  organisations.map((organisation) => {
                    return (
                        <CDropdownItem
                            key={`${organisation.id}-toggle`}
                            className={currentOrganisation.id === organisation.id ? 'active' : ''}
                            onClick={() => {
                              toggleOrganisation(organisation);
                            }}
                        >{organisation.name}</CDropdownItem>
                    )
                  })
                }
              </CDropdownMenu>
            </CDropdown>
        ) : (
            <p className="m-0">{config.organisationName}</p>
        )}
      </CNavbarNav>

      <CHeaderNav className="px-3">
        <CDropdown className="m-0">
          <CDropdownToggle caret color="light">
            {currentEnvironment.name}
          </CDropdownToggle>
          <CDropdownMenu>
            {
              currentOrganisation.environments.map((environment) => {
                return (
                    <CDropdownItem
                        key={`${environment .id}-toggle`}
                        className={currentEnvironment.id === environment.id ? 'active' : ''}
                        onClick={() => {
                          toggleEnvironment(environment);
                        }}
                    >{environment.name}</CDropdownItem>
                )
              })
            }
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>

      <CHeaderNav className="px-3 ml-lg-auto">
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={() => dispatch({type: 'set', darkMode: !darkMode})}
          title="Toggle Light/Dark Mode"
        >
          <CIcon name="cil-moon" className="c-d-dark-none" alt="CoreUI Icons Moon"/>
          <CIcon name="cil-sun" className="c-d-default-none" alt="CoreUI Icons Sun"/>
        </CToggler>
        <CNavbarNav className="ml-auto">
          <CDropdown
            inNav
          >
            <CDropdownToggle color="primary" caret={false} className="d-flex align-items-center">
              <div className="c-avatar">
                <img className="c-avatar-img" src={avatar} alt="Profile"/>
              </div>
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={() => handleLogout()}>Logout</CDropdownItem>
              <CDropdownItem onClick={() => getToken()}>Get Token</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CNavbarNav>
        {/*<CToggler*/}
        {/*  inHeader*/}
        {/*  className="d-md-down-none"*/}
        {/*  onClick={() => dispatch({type: 'set', asideShow: !asideShow})}*/}
        {/*>*/}
        {/*  <CIcon className="mr-2" size="lg" name="cil-applications-settings"/>*/}
        {/*</CToggler>*/}
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes}/>
      </CSubheader> */}

    </CHeader>
  )
}

export default TheHeader
