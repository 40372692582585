import React from 'react';
import EventAttendeeList from "./EventAttendeeList";

const EventDetailAudience = ({eventAttendees}) => {

    return <>
        {eventAttendees && (
            <EventAttendeeList attendeeData={eventAttendees.attendees} />
        )}
    </>

}

export default EventDetailAudience;