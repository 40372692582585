import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import history from '../../history';
import {createEntity, updateEntity, getEntity, reset} from '../../reducers/apiKeyReducer';
import {useForm} from "react-hook-form";
import {
    CFormGroup,
    CLabel
} from "@coreui/react";
import {contextAwareUrl} from "../../helpers";

const ApiKeyUpdateForm = ({id}) => {
    const dispatch = useDispatch();

    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const key = useSelector(state => state.apikey.entity);
    const loading = useSelector(state => state.apikey.loading);
    const updating = useSelector(state => state.apikey.updating);
    const updateSuccess = useSelector(state => state.apikey.updateSuccess);
    const errorMessage = useSelector(state => state.apikey.errorMessage);
    const [isNew] = useState(!id);

    // initial load
    useEffect(() => {
        if (isNew) {
            dispatch(reset());
        } else {
            dispatch(getEntity(id));
        }
    }, []);

    // // if event changes reload form
    useEffect(() => {
        form.reset({
            ...key
        });
    }, [key]);

    // if successful save redirect back to events
    useEffect(() => {
        if (updateSuccess) {
            history.push(contextAwareUrl('/apikeys'));
        }
    }, [updateSuccess]);

    const form = useForm({
        defaultValues: isNew ? {} : key
    });

    const saveEntity = (formData) => {
        const keyData = {
            "environmentId": currentEnvironment.id,
            "name": formData.name
        };

        if (isNew) {
            dispatch(createEntity(keyData));
        } else {
            keyData.id = id;
            dispatch(updateEntity(keyData));
        }
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <form onSubmit={form.handleSubmit(saveEntity)}>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header"><strong>{isNew ? 'Create' : 'Edit'} Api Key</strong></div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <CFormGroup>
                                                    <CLabel htmlFor="title">Name</CLabel>
                                                    <input type="text" className="form-control" name="name" ref={form.register} />
                                                </CFormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className={`col-md-2`}>
                            <button type="submit" className="btn btn-primary d-flex align-items-center"
                                    disabled={updating}>
                                <span className="cil-check-alt btn-icon mr-2"></span>
                                Save key
                            </button>
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage.message}</div>
                    )}
                </form>
            )}
        </>
    )
};

export default ApiKeyUpdateForm;
