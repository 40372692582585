import React from 'react';
import formConfig from "../../config/formConfig.json";
import TextField from "../common/TextField";
import FileField from "../common/FileField";

const EventUpdateFormMediaAssets = ({form}) => {
    return <>
        <h3 className="py-2">Media Assets</h3>

        { formConfig.config.fields?.trailerUrl?.show &&
            <TextField
                name="custom.trailerUrl"
                config={formConfig.config?.fields?.trailerUrl}
                form={form}
            />
        }

        { formConfig.config.fields?.programmePdf?.show &&
            <FileField name="programmeFile"
                       config={formConfig.config.fields?.programmePdf}
                       form={form}
                       accept="application/pdf"
                       type="file"
            />
        }

        {formConfig.config.fields?.hero?.show &&
            <FileField name="bannerImage"
                       config={formConfig.config.fields?.hero}
                       form={form}
                       accept="image/*"
                       type="image"
            />
        }

        { formConfig.config.fields?.thumbnail?.show &&
            <FileField name="thumbnailImage"
                       config={formConfig.config.fields?.thumbnail}
                       form={form}
                       accept="image/*"
                       type="image"
            />
        }
    </>
};

export  default EventUpdateFormMediaAssets;