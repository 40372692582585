import {MODAL_HYDRATE,MODAL_CANCEL,MODAL_CLOSE,MODAL_OPEN,MODAL_PROCEED, LOGOUT} from "./types";

export const hydrateModal = (payload) => ({
    type : MODAL_HYDRATE,
    payload : {
        ...payload,
        modalActionState : null
    }
});

export const hydrateModalAndOpen = (payload) => ({
    type : MODAL_HYDRATE,
    payload : {
        ...payload,
        modalActionState : null,
        open : true
    }
});

export const cancelModal = () => ({
    type : MODAL_CANCEL,
});

export const proceedModal = () => ({
    type : MODAL_PROCEED,
});