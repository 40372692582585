import { AUTHENTICATED, LOGOUT } from "../actions/types";

const INITIAL_STATE = {
    authenticated: null,
    token: null,
    errorMessage : ''
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };
        case LOGOUT:
            return {
                ...state,
                authenticated: false
            };
        default:
            return state;
    }
}
