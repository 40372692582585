import React from 'react';
import formConfig from "../../config/formConfig.json";
import {CFormGroup, CLabel} from "@coreui/react";
import {Controller} from "react-hook-form";
import TextEditor from "../../modules/TextEditor";
import Wysiwyg from "../common/Wysiwyg";
import TextField from "../common/TextField";
import DateField from "../common/DateField";
import TextArea from "../common/TextArea";

const EventUpdateFormSetup = ({handleTitleChange,form}) => {

    return <>

        <h3 className="py-2">Setup</h3>

        { formConfig.config.fields?.title?.show &&
            <TextField
                config={formConfig.config.fields.title}
                name="title"
                form={form}
                handleChange={handleTitleChange}
            />
        }
        { formConfig.config.fields?.slug?.show &&
            <TextField
                config={formConfig.config.fields?.slug}
                name="slug"
                form={form}
            />
        }
        { formConfig.config.fields?.start?.show &&
        <DateField
            config={formConfig.config.fields?.start}
            name="start"
            form={form}
        />
        }
        { formConfig.config.fields?.introduction?.show &&
            <TextArea
                name="introduction"
                config={formConfig.config.fields?.introduction}
                form={form}
            />
        }
        { formConfig.config.fields?.description?.show &&
            <Wysiwyg
                name="description"
                config={formConfig.config.fields?.description}
                form={form}
                />
        }

    </>

};

export default EventUpdateFormSetup;