import React, {useEffect, useState} from 'react';
import {userNotes} from "../../data/userNotes";
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormGroup,
    CListGroup,
    CListGroupItem,
    CRow,
    CTextarea} from "@coreui/react";

import dayjs from "dayjs";

import {useUserSelector} from "../../hooks/userSelector";

const Notes = () => {

    const [_userNotes, set_UserNotes] = useState([]);

    const user = useUserSelector();

    useEffect(() => {

        set_UserNotes(userNotes);

    }, []);

    const handleNoteAddition = (e) => {

        e.persist();
        e.preventDefault();

        const note = e.target[0].value;

        set_UserNotes([
            ..._userNotes,
            {
                id: user.id,
                note: note,
                date: dayjs().format('H:mm, dddd D MMMM YYYY'),
                author: user.attributes.email
            }
        ]);

        e.target[0].value = '';

    };

    return (
        <CRow>
            <CCol >
                <CCard className={'shadow-none'}>
                    <CCardHeader>
                        Notes
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup>
                            {_userNotes.map((userNote, i) => {
                                return (
                                    <CListGroupItem >
                                        <p className={'m-0'}><small><strong>{userNote.author}</strong></small></p>
                                        <p><small>{userNote.date}</small></p>
                                        <p>{userNote.note}</p>
                                    </CListGroupItem>
                                )
                            })}
                        </CListGroup>
                    </CCardBody>
                    <CCardBody>
                        <CForm onSubmit={handleNoteAddition}>
                            <CFormGroup>
                                {/*<CLabel htmlFor="add-note">Add note</CLabel>*/}
                                <CTextarea
                                    id="add-note"
                                    placeholder="Enter your note here"
                                />
                            </CFormGroup>
                            <button type="submit" className="btn btn-primary d-flex align-items-center">
                                                    <span className="cil-check-alt btn-icon mr-2">
                                                    </span>
                                Add note
                            </button>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
};

export default Notes;
