import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {
    CDataTable, CButton, CModal, CModalHeader, CModalBody, CModalFooter, CBadge,
} from '@coreui/react'

import {Link} from 'react-router-dom';
import {getEntities, deleteEntity} from '../../reducers/webhookReducer';
import {contextAwareUrl} from "../../helpers";
import useModal from "../../hooks/useModal";

const Webhooks = () => {
    const dispatch = useDispatch();
    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const webhooks = useSelector(state => state.webhook.entities);
    const loading = useSelector(state => state.webhook.loading);
    const updateSuccess = useSelector(state => state.webhook.updateSuccess);
    const errorMessage = useSelector(state => state.webhook.errorMessage);

    const [createDeleteModal] = useModal({
        cancelButton : 'Cancel',
        message : `You will permanently delete this webhook.`,
        proceedButton : 'Delete',
        title : 'Are you sure?',
        type : 'DANGER'
    })

    const showDeleteModal = (webhook)=> {
        console.log('showDeleteModal',webhook);
        createDeleteModal(() => {
            dispatch(deleteEntity(webhook.id));
        });
    };



    useEffect(() => {
        if (updateSuccess) {
            dispatch(getEntities(currentEnvironment.id));
        }
    }, [updateSuccess]);

    // initial load request events
    useEffect(() => {
        dispatch(getEntities(currentEnvironment.id));
    }, [currentEnvironment]); // reload if environment changes

    const fields = [
        {key: 'name', _style: {width: '30%'}},
        {key: 'url', _style: {width: '30%'}},
        {key: 'enabled', _style: {width: '30%'}},
        {
            key: 'edit_link',
            label: '',
            _style: {width: '1%'},
            sorter: false,
            filter: false
        },
        {
            key: 'delete_link',
            label: '',
            _style: {width: '1%'},
            sorter: false,
            filter: false
        }
    ];

    return (
        <>

            <Link to={contextAwareUrl("/webhooks/new")} className="btn btn-primary w-fit ml-auto mb-3 d-flex align-items-center">
                <span className="cil-plus btn-icon mr-2"></span> Create new webhook
            </Link>

            {webhooks && webhooks.length > 0 ? (
                <div className={'card shadow-none'}>
                    <div className={'card-body table-responsive'}>
                        <h5 className={'card-title'}>Webhooks</h5>

                        <CDataTable
                            items={webhooks}
                            fields={fields}
                            columnFilter
                            tableFilter
                            itemsPerPageSelect
                            itemsPerPage={50}
                            hover
                            sorter
                            pagination
                            striped
                            scopedSlots={{
                                'enabled':
                                    (item) => (
                                        <td>
                                            <CBadge color={item.enabled ? 'success' : 'danger'}>
                                                {item.enabled ? 'enabled' : 'disabled'}
                                            </CBadge>
                                        </td>
                                    ),
                                'edit_link':
                                    (item) => {
                                        return (
                                            <td className="py-2">
                                                <CButton
                                                    color="primary"
                                                    shape="rounded"
                                                    size="sm"
                                                    className="d-flex align-items-center"
                                                    to={contextAwareUrl('/webhooks/' + item.id + '/edit')}>
                                                    <span className={'cil-cog cil-button mr-2'}></span> Edit
                                                </CButton>
                                            </td>
                                        )
                                    },
                                'delete_link':
                                    (item) => {
                                        return (
                                            <td className="py-2">
                                                <CButton
                                                    color="danger"
                                                    shape="rounded"
                                                    size="sm"
                                                    className="d-flex align-items-center"
                                                    onClick={() => {showDeleteModal(item)}}>
                                                    <span className={'cil-cog cil-button mr-2'}></span> Delete
                                                </CButton>
                                            </td>
                                        )
                                    },
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {errorMessage ? (
                                <div className="alert alert-danger">{errorMessage.message}</div>
                            ) : (
                                <div className="alert alert-warning">No webhooks found</div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
};

export default Webhooks;
