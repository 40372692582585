import React, {Suspense, useEffect} from 'react'
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

// routes config
import routes from '../routes'
import {useDispatch, useSelector} from "react-redux";
import {setCurrentEnvironment, setCurrentOrganisation} from "../reducers/contextReducer";

import history from "../history";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const EnvHoc = ({match, children}) => {

    const dispatch = useDispatch();
    // const currentOrganisation = useSelector(state => state.context.currentOrganisation);
    const organisations = useSelector(state => state.organisation.entities);

    useEffect(() => {

        // check if environment set in URL
        if (match && match.params && match.params.env) {
            // check if org set in URL
            if (match.params.org) {
                //check if current org in redux is already same as the one being requested
                    if (organisations.map((organisation) => {
                        return organisation.id.toString().toLowerCase();
                    }).includes(match.params.org)) {

                        const matchedOrganisation = organisations.find(organisation => organisation.id.toString().toLowerCase() === match.params.org);

                        if (matchedOrganisation.environments.map((environment) => {
                            return environment.id.toString().toLowerCase()
                        }).includes(match.params.env) ) {

                            dispatch(setCurrentOrganisation(matchedOrganisation));
                            dispatch(setCurrentEnvironment(matchedOrganisation.environments.find(environment => {
                                return environment.id.toString().toLowerCase() === match.params.env
                            })))
                        } else {
                            console.log('NON MATCH PARAMS ENVIRONMENT');
                            console.log(match.params);
                            console.log(organisations);
                            console.log(matchedOrganisation);
                            dispatch(setCurrentOrganisation(matchedOrganisation));


                            const rawSlug = history.location.pathname.split('/').slice(5).join().replaceAll(',','/');

                            console.log(`/org/${matchedOrganisation.id.toString().toLowerCase()}/env/${matchedOrganisation.environments[0].id}/${rawSlug}`);


                            history.push(`/org/${matchedOrganisation.id.toString().toLowerCase()}/env/${matchedOrganisation.environments[0].id}/${rawSlug}`);



                            // history.push('/dashboard');
                        }


                    } else {

                        console.log('NON MATCH PARAMS ORG');
                        console.log(match.params);
                        console.log(organisations);
                        history.push('/dashboard');

                    }

                }

        }

    }, [organisations, match]);

    return children

};

const TheContent = () => {

  return (
    <main className="c-main">
      <CContainer fluid>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <EnvHoc match={props.match}>
                        <route.component {...props} />
                      </EnvHoc>
                    </CFade>
                  )} />
              )
            })}
            <Redirect from="/" to="/dashboard" />
          </Switch>
      </CContainer>
    </main>
  )
};

export default React.memo(TheContent)
