import React, {useState} from 'react';

import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import useForm from "../../hooks/useForm";
import {asyncAmplifyForgotPassword, forceChangePassword} from "../../actions/auth";
import {useDispatch} from "react-redux";

import history from "../../history";
import {Form} from "reactstrap";

const ForgotPassword = () => {

    const dispatch = useDispatch();

    const {
        values,
        handleFieldChange,
        loading, setLoading,
        error, setError,
        errorMessage, setErrorMessage
    } = useForm({email: ''});

    const handleSubmit = async (event) => {

        event.preventDefault();

        setLoading(true);
        setError(false);
        setErrorMessage('');

        dispatch(asyncAmplifyForgotPassword({
            email: values.email,
        })).then((result) => {
            console.log('result: ', result);
            setLoading(false);
            history.push(`/resetpassword?email=${values.email}`);
        }).catch((err) => {
            console.log('err: ', err);
            setError(true);
            setErrorMessage(err.message);
            setLoading(false);
        });
    };

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="9" lg="7" xl="6">
                        <CCard className="mx-4">
                            <CCardBody className="p-4">
                                <CForm onSubmit={handleSubmit}>
                                    <h1>Request to reset your password.</h1>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupPrepend>
                                            <CInputGroupText>@</CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput type="email" required id="email" name="email" onChange={handleFieldChange} placeholder="Email" autoComplete="email" />
                                    </CInputGroup>
                                    {
                                        loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )
                                            :
                                                <CButton type="submit" color="success" block>Reset</CButton>
                                    }
                                </CForm>
                                {
                                    error && (
                                        <div className="alert pt-2 mt-2 alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )
                                }
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
};

export default ForgotPassword;
