import {sygnet} from './sygnet'
import {logo} from './logo'
import {logoNegative} from './logo-negative'

import {
    cilApplicationsSettings,
    cilAudio,
    cilCalendar,
    cilCloudUpload,
    cilCog,
    cilClock,
    cilMoney,
    cilMusicNote,
    cilPencil,
    cilPeople,
    cilSmile,
    cilSpeedometer,
    cilSun,
    cilTrash,
    cilMoon,
    cilLink,
    cibOpenAccess,
    cilSettings

} from '@coreui/icons'

export const icons = Object.assign({}, {
    sygnet,
    logo,
    logoNegative
}, {
    cilApplicationsSettings,
    cilAudio,
    cilCalendar,
    cilCloudUpload,
    cilCog,
    cilClock,
    cilMoney,
    cilMusicNote,
    cilPencil,
    cilPeople,
    cilSmile,
    cilSpeedometer,
    cilSun,
    cilTrash,
    cilMoon,
    cilLink,
    cibOpenAccess,
    cilSettings
})
