import React from 'react'
import { CFooter, CLink } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div className="ml-auto">
        <span className="mr-1">&copy; 4Forty { new Date().getFullYear() }. Developed by <CLink href="https://spork.digital" target="_blank">Spork Digital.</CLink></span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
