import api from "../api";

import {REQUEST, SUCCESS, FAILURE} from './action-type.util';

export const ACTION_TYPES = {
    FETCH_ENVIRONMENT_LIST: 'ENVIRONMENT/FETCH_ENVIRONMENT_LIST',
    FETCH_ENVIRONMENT: 'ENVIRONMENT/FETCH_ENVIRONMENT',
    CREATE_ENVIRONMENT: 'ENVIRONMENT/CREATE_ENVIRONMENT',
    UPDATE_ENVIRONMENT: 'ENVIRONMENT/UPDATE_ENVIRONMENT',
    DELETE_ENVIRONMENT: 'ENVIRONMENT/DELETE_ENVIRONMENT',
    RESET: 'ENVIRONMENT/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: {},
    updating: false,
    updateSuccess: false,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_ENVIRONMENT_LIST):
        case REQUEST(ACTION_TYPES.FETCH_ENVIRONMENT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_ENVIRONMENT):
        case REQUEST(ACTION_TYPES.UPDATE_ENVIRONMENT):
        case REQUEST(ACTION_TYPES.DELETE_ENVIRONMENT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_ENVIRONMENT_LIST):
        case FAILURE(ACTION_TYPES.FETCH_ENVIRONMENT):
        case FAILURE(ACTION_TYPES.CREATE_ENVIRONMENT):
        case FAILURE(ACTION_TYPES.UPDATE_ENVIRONMENT):
        case FAILURE(ACTION_TYPES.DELETE_ENVIRONMENT):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_ENVIRONMENT_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_ENVIRONMENT):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_ENVIRONMENT):
        case SUCCESS(ACTION_TYPES.UPDATE_ENVIRONMENT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ENVIRONMENT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = '/system/environments';

// Actions
export const getEntities = (organisationId) => ({
    type: ACTION_TYPES.FETCH_ENVIRONMENT_LIST,
    payload: api.instance.get(`${apiUrl}?organisationId=${organisationId}&cacheBuster=${new Date().getTime()}`),
});

export const getEntity = id => ({
    type: ACTION_TYPES.FETCH_ENVIRONMENT,
    payload: api.instance.get(`${apiUrl}/${id}`),
});

export const createEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_ENVIRONMENT,
        payload: api.instance.post(`${apiUrl}`, entity),
    });
};

export const updateEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.UPDATE_ENVIRONMENT,
        payload: api.instance.put(`${apiUrl}/${entity.id}`, entity),
    });
};

export const deleteEntity = id => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.DELETE_ENVIRONMENT,
        payload: api.instance.delete(`${apiUrl}/${id}`),
    });
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
