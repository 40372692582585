import React from 'react';
import {CButton, CButtonGroup, CCard, CCardBody, CFormGroup, CLabel,CInputFile} from "@coreui/react";


const FormFieldArray = ({
    config,
    configKey,
    data,
    defaultData,
    form,
}) => {

    const fieldArrayValues = Object.values(config.fieldArray);
    const fieldArrayKeys = Object.keys(config.fieldArray);

    console.log('FormFieldArray fieldArrayValues', fieldArrayValues);
    console.log('FormFieldArray fieldArrayKeys', fieldArrayKeys);



    return <CCard>
            <CCardBody>
                <h5 className="mb-3">{config.label}</h5>
                <CFormGroup>
                    {data.fields.map((item, dataIndex) => {

                        console.log("FormFieldArray item", item);

                        return <div className={"row mb-3"} key={item.id}>

                            { fieldArrayKeys.map( (arrayKey, fieldIndex) => {
                                return <>{
                                    fieldArrayValues[fieldIndex].show &&
                                    <div className="col">
                                        {dataIndex === 0 &&
                                        <p><b>{fieldArrayValues[fieldIndex].label}</b></p>}
                                        <div className="position-relative">
                                        {
                                            fieldArrayValues[fieldIndex].type === 'text' &&
                                            <input type="text" className="form-control"
                                                   name={`custom.${configKey}[${dataIndex}].${arrayKey}`}
                                                   defaultValue={item[arrayKey]} ref={form.register()}/>
                                        }
                                        {
                                            fieldArrayValues[fieldIndex].type === 'number' &&
                                            <input type="number" className="form-control"
                                                   name={`custom.${configKey}[${dataIndex}].${arrayKey}`}
                                                   defaultValue={item[arrayKey]} ref={form.register()}/>
                                        }
                                        {
                                            fieldArrayValues[fieldIndex].type === 'date' &&
                                            <input type="date" className="form-control"
                                                   name={`custom.${configKey}[${dataIndex}].${arrayKey}`}
                                                   defaultValue={item[arrayKey]} ref={form.register()}/>
                                        }
                                        {
                                            fieldArrayValues[fieldIndex].type === 'file' && <>
                                                <CInputFile
                                                    custom
                                                    id={`custom.${configKey}[${dataIndex}].${arrayKey}`}
                                                    name={`custom.${configKey}[${dataIndex}].${arrayKey}`}
                                                    defaultValue={item[arrayKey]}
                                                    ref={form.register()}
                                                />
                                                <CLabel
                                                    htmlFor={`custom.${configKey}[${dataIndex}].${arrayKey}`}
                                                    variant="custom-file">
                                                Choose file...
                                                </CLabel>
                                            </>
                                        }
                                        </div>
                                    </div>

                                }</>

                        })}

                        {data.fields.length > 0 &&
                            <div className="col-auto">
                                {dataIndex === 0 && <p>&nbsp;</p>}
                                <CButtonGroup>
                                    {data.fields.length > 1 &&
                                        <>
                                            <CButton
                                                onClick={() => data.swap(dataIndex, dataIndex - 1)}
                                                disabled={dataIndex === 0}
                                                color="secondary"><span className="cil-chevron-top btn-icon"/></CButton>
                                            <CButton
                                                onClick={() => data.swap(dataIndex, dataIndex + 1)}
                                                disabled={dataIndex === data.fields.length - 1}
                                                color="secondary"><span className="cil-chevron-bottom btn-icon"/></CButton>
                                        </>
                                    }
                                    <CButton
                                        onClick={() => data.remove(dataIndex)}
                                        color="danger"><span className="cil-trash btn-icon" /></CButton>
                                </CButtonGroup>
                            </div>
                            }
                        </div>
                    })}
                </CFormGroup>
                <button type="button" className="btn btn-info my-2"
                        onClick={() => data.append(defaultData)}><span className="cil-plus btn-icon" /> Add
                </button>
            </CCardBody>
        </CCard>
}

export default FormFieldArray;