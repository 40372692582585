import {LOGOUT, SET_USER} from "../actions/types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_USER:
            return payload;
        case LOGOUT:
            return null;
        default:
            return state
    }
}
