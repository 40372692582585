import React, {useEffect, useState} from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CSwitch,
    CAlert
    } from '@coreui/react'

import {
    CChartBar,
    CChartLine,
    CChartDoughnut,
    CChartRadar,
    CChartPie,
    CChartPolarArea
} from '@coreui/react-chartjs'

import api from "../../api";
import {userData} from "../../data/userData";
import transformAudienceList from "../../transforms/audienceList";
import UserEvents from "./UserEvents";
import useEditUserForm from '../../hooks/useEditUserForm';
import Notes from "../notes";
import {useSelector, useDispatch} from "react-redux";
import {getEntities} from "../../reducers/eventReducer";


const AudienceAdmin = ({id}) => {
    const dispatch = useDispatch();
    const [account, setAccount] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [registeredEvents, setRegisteredEvents] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const events = useSelector(state => state.event.entities);
    const currentEnvironment = useSelector(state => state.context.currentEnvironment);

    const {
        values,
        handleFieldChange,
        resetValues
      } = useEditUserForm({
        userformFirstname: account?.firstName,
        userformLastname: account?.lastName,
        userformEmail: account?.email,
        userformMarketingconsent: account?.optIn
    });

    // initial load request events
    useEffect(() => {
        dispatch(getEntities(currentEnvironment.id));
    }, [currentEnvironment]); // reload if environment changes

    useEffect(() => {
        console.log(id);

        //TODO: ADD IF THEY PAID USING STRIPE
        //TODO: THIS HAS TO BE EVENTUALLY DRIVEN BY TICKETS

        api.instance.get(`/admin/audience/${id}`).then((result) => {
            setAccount(result.data);
            setRegisteredEvents(result.data.registeredEvents);
            console.log('result data: ', result.data);
        });

        // api.instance.get(`/admin/events/${id}/attendance`).then((result) => {
        //     setAttendingUsers(result.data);
        //     setUsersLoaded(true);
        //     console.log('attendence: ', result.data);
        // });
    }, [events]);

    const addEvents = (eventsToAdd) => {

        const _eventsToAdd = eventsToAdd.map(event => {
            return event.value
        });

        let _registeredEvents = registeredEvents;

        _registeredEvents = [..._registeredEvents, ..._eventsToAdd]

        api.instance.post(`/admin/audience/${account.id}/events`, {events: _registeredEvents}).then((result) => {
            console.log('callEventEndpoint: ', result.data);
            setRegisteredEvents(_registeredEvents);
            // handleVideoUpdate(result.data);
        });

    };

    const removeEvent = (id) => {

        let _registeredEvents = registeredEvents;

        if (_registeredEvents.includes(id)) {
            _registeredEvents = _registeredEvents.filter(rEvent => rEvent !== id)
        }

        api.instance.post(`/admin/audience/${account.id}/events`, {events: _registeredEvents}).then((result) => {
            console.log('callEventEndpoint: ', result.data);
            setRegisteredEvents(_registeredEvents);
            // handleVideoUpdate(result.data);
        });

    };

    const toggleAccess = (e, id) => {

        e.persist();

        let _registeredEvents = registeredEvents;

        if (_registeredEvents.includes(id)) {
            _registeredEvents = _registeredEvents.filter(rEvent => rEvent !== id)
        } else {
            _registeredEvents = [..._registeredEvents, id]
        }

        api.instance.post(`/admin/audience/${account.id}/events`, {events: _registeredEvents}).then((result) => {
            console.log('callEventEndpoint: ', result.data);
            setRegisteredEvents(_registeredEvents);
            // handleVideoUpdate(result.data);
        });

        console.log('toggleAccess: ', e);
        console.log('toggleAccess Id: ', id);

    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
        resetValues();
    }

    const sentEmailNotification = CAlert

    const handlePasswordReset = () => {
        setShowAlert(true);
    }

    const renderUser = () => {

       return (
           <div className={'card shadow-none'}>
               <div className={'card-body'}>
                   <div className="w-100 d-flex justify-content-end pb-3">

                        <div className={editMode ? 'btn btn-danger d-flex align-items-center' : 'btn btn-primary d-flex align-items-center' } onClick={toggleEditMode}>
                            <span className={editMode ? 'cil-x btn-icon mr-2' : 'cil-pencil btn-icon mr-2'}></span>
                            {editMode ? 'Cancel editing' : 'Edit user'}
                        </div>

                   </div>
                    <CForm action="" method="post">
                        <CFormGroup className="row align-items-center">
                            <div className="col-12 col-sm-4 col-md-3 m-0">
                                <CLabel htmlFor="userformName">Name</CLabel>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 d-flex">
                                <div className="row w-100 no-gutters">
                                    <div className="col-12 col-sm-6 pr-sm-2">
                                        <CInput
                                            type="text"
                                            id="userformFirstname"
                                            name="userformFirstname"
                                            value={`${values?.userformFirstname}`}
                                            onChange={handleFieldChange}
                                            readOnly={!editMode}
                                            disabled={!editMode}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 pl-sm-2">
                                        <CInput
                                            type="text"
                                            id="userformLastname"
                                            name="userformLastname"
                                            value={`${values?.userformLastname}`}
                                            onChange={handleFieldChange}
                                            readOnly={!editMode}
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CFormGroup>
                        <CFormGroup className="row align-items-center">
                            <div className="col-12 col-sm-4 col-md-3 m-0">
                                <CLabel htmlFor="userformEmail">Email</CLabel>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9">
                                <CInput
                                    type="email"
                                    id="userformEmail"
                                    name="userformEmail"
                                    value={`${values?.userformEmail}`}
                                    onChange={handleFieldChange}
                                    readOnly={!editMode}
                                    disabled={!editMode}
                                />
                            </div>

                        </CFormGroup>
                        <CFormGroup className="row align-items-center">
                            <div className="col-12 col-sm-4 col-md-3 m-0">
                                <CLabel htmlFor="userformMarketingconsent">Marketing consent</CLabel>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9">
                                <CSwitch
                                    id="userformMarketingconsent"
                                    name="userformMarketingconsent"
                                    checked={`${values?.userformMarketingconsent}`}
                                    onChange={handleFieldChange}
                                    shape={'pill'}
                                    color={'success'}
                                    labelOn={'\u2713'}
                                    labelOff={'\u2715'}
                                    readOnly={!editMode}
                                    disabled={!editMode}
                                />
                            </div>
                        </CFormGroup>
                    </CForm>

                    <div className="d-flex justify-content-between flex-wrap">
                        <CButton onClick={handlePasswordReset} className="my-2 d-flex align-items-center" color="warning"><span className={'cil-reload btn-icon mr-2'}></span>Force password reset</CButton>
                        {editMode ? <CButton className="my-2 d-flex align-items-center" color="success"><span className={'cil-check-alt btn-icon mr-2'}></span>Save changes</CButton> : ''}
                    </div>

                    {<CAlert color="success" closeButton onShowChange={setShowAlert} show={showAlert} className={'my-2'} >
                        Password reset email sent.
                    </CAlert>}

                </div>
            </div>
       )
    };

    const renderPurchases = () => {

        return <>
            <div className={'card shadow-none'}>
                <div className={'card-body'}>
                    <div className="row">
                        <div className="col-3">
                            <h3>
                                Event
                            </h3>
                        </div>
                        <div className="col-3">
                            <h3>
                                Has access
                            </h3>
                        </div>
                        <div className="col-3">
                            <h3>
                                Payment method
                            </h3>
                        </div>
                        <div className="col-3">
                            <h3>
                                Payment date
                            </h3>
                        </div>
                    </div>
                    {
                        renderEvents()
                    }
                </div>
            </div>
        </>

    };

    const renderStats = () => {
        return <><CCard className="shadow-none">
            <CCardHeader>
                Stats
            </CCardHeader>
            <CCardBody>
                <CChartBar
                    datasets={[
                        {
                            label: 'Video views',
                            backgroundColor: '#f87979',
                            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                        }
                    ]}
                    labels="months"
                    options={{
                        tooltips: {
                            enabled: true
                        }
                    }}
                />
            </CCardBody>
            <CCardBody>
            <CChartLine
        datasets={[
                {
                    label: 'Event 1 Views',
                    backgroundColor: 'rgb(228,102,81,0.9)',
                    data: [30, 39, 10, 50, 30, 70, 35]
                },
                {
                    label: 'Event 2 Views',
                        backgroundColor: 'rgb(0,216,255,0.9)',
                    data: [39, 80, 40, 35, 40, 20, 45]
                }
        ]}
        options={{
            tooltips: {
                enabled: true
            }
        }}
        labels="months"
            />
            </CCardBody>
        </CCard>
            </>
    }

    const renderEvents = () => {

        if (!events) {
            return null
        }

        return <UserEvents
                events={events}
                registeredEvents={registeredEvents}
                toggleAccess={toggleAccess}
                addEvents={addEvents}
                removeEvent={removeEvent}
                />

        // return events.map((event) => {
        //    return <div key={event.id} className="row">
        //             <div className="col-3">
        //                 {event.title}
        //             </div>
        //             <div className="col-3">
        //                 <CSwitch checked={registeredEvents?.includes(event.id)} onChange={(e) => toggleAccess(e, event.id)} shape={'pill'} color={'success'} labelOn={'\u2713'}
        //                          labelOff={'\u2715'}/>
        //             </div>
        //             {/*TODO: Get from API*/}
        //            <div className="col-3">
        //                Stripe
        //            </div>
        //            <div className="col-3">
        //                {`Saturday, 19th September, 2020`}
        //            </div>
        //    </div>
        // })
    };

    return (
        <>
            {account &&
                <>


            <div className="row align-items-center mb-3">
              <div className="col-12 col-sm-8">
                <h3 className={'card-title m-0'}>
                    {`${account.firstName} ${account.lastName}` }
                </h3>
              </div>
                <div className="col-12 col-sm-4">
                  <div className={'d-flex justify-content-end'}>
                    <CButton color="danger" className="m-2 d-flex align-items-center"><span class="cil-ban btn-icon mr-2"></span> Ban user</CButton>
                  </div>
                </div>
              </div>

                    <CTabs activeTab="user">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="user">
                                    User
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="events">
                                    Events
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="stats">
                                    Stats
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="notes">
                                    Notes
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="user">
                                {renderUser()}
                            </CTabPane>
                            <CTabPane data-tab="events">
                                {renderEvents()}
                            </CTabPane>
                            <CTabPane data-tab="stats">
                                {renderStats()}
                            </CTabPane>
                            <CTabPane data-tab="notes">
                                <Notes />
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </>
            }
        </>
    )
}

export default AudienceAdmin;
