import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";

import history from '../../history';

const Redirect = (props) => {

    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const currentOrganisation = useSelector(state => state.context.currentOrganisation);

    useEffect(() => {

        const {id : envId} = currentEnvironment;
        const {id : orgId} = currentOrganisation;

        const envSlug = envId.toString().toLowerCase();

        history.push(`org/${orgId}/env/${envSlug}/dashboard`)

    } ,[currentEnvironment, currentOrganisation]);

    return (
        <div>
            Redirecting...
        </div>
    );
};

export default Redirect;
