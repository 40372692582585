import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ButtonToolbar, CardBody, InputGroup} from "reactstrap"
import {
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CButton,
    CButtonGroup,
    CWidgetProgress,
    CTooltip,
    CCard,
    CCardBody,
    CCardHeader
} from '@coreui/react'

import {contextAwareUrl, displayDateTime,displayDateTimeInContext} from '../../helpers'
import Player from './Player';
import {getEntity, deleteEntity} from "../../reducers/eventReducer";
import {getEntities as getEventAttendees, getStats} from "../../reducers/eventAttendeeReducer";
import EventButtons from './EventButtons';
import history from "../../history";
import EventAttendeeList from "./EventAttendeeList";
import {useInterval} from "../../hooks/useInterval";
import {encode,decode} from 'html-entities';
import {Badge, Card, } from "react-bootstrap";
import useModal from "../../hooks/useModal";
import EventDetailStreaming from "./EventDetailStreaming";
import EventDetailContent from "./EventDetailContent";
import EventDetailAudience from "./EventDetailAudience";
import EventDetailMessages from "./EventDetailMessages";

const EventDetail = ({id}) => {
    const dispatch = useDispatch();

    const event = useSelector(state => state.event.entity);
    const eventLoading = useSelector(state => state.event.loading);
    const updateSuccess = useSelector(state => state.event.updateSuccess);

    const eventAttendees = useSelector(state => state.eventAttendee.entities);
    const eventAttendeesLoading = useSelector(state => state.eventAttendee.loading);

    const eventAttendeeStats = useSelector(state => state.eventAttendee.stats);

    const [rmtps, setRmtps] = useState(true);

    const confirmDelete = () => {
        dispatch(deleteEntity(event));
    };

    const handleVideoUpdate = (videoEvent) => {
        console.log('handleVideoUpdate: ', videoEvent);
        dispatch(getEntity(id));
    };

    const handleEnableRmtps = () => {
        setRmtps(true);
    }

    const handleDisableRmtps = () => {
        setRmtps(false);
    }

    const [openDeleteModal] = useModal({
        cancelButton : 'Cancel',
        message : `This will permanently delete the event <b><i>${event.title}</i></b> on ${displayDateTimeInContext(event.start)}.`,
        proceedButton : 'Delete',
        title : 'Are you sure?',
        type : 'DANGER'
    });

    const handleDeleteModal = () => {
        openDeleteModal(confirmDelete);
    }

    const getStatusText = () => {
        switch (event.streamStatus) {
            case 'pending':
                return 'Pending';
            case 'open':
                return 'Open';
            case 'closed':
                return 'Closed';
            case 'live':
                return 'Online';
            case 'live_interrupted':
                return 'Offline';
            case 'live_finished':
                return 'Finished';
            case 'vod':
                return 'On Demand';
        }
    };

    useEffect(() => {
        if (updateSuccess) {
            history.push(contextAwareUrl('/events'));

        }
    }, [updateSuccess]);

    useEffect(() => {
        dispatch(getEntity(id));
    }, []);

    useEffect(() => {
        dispatch(getEventAttendees(id));
        dispatch(getStats(id));
    }, []);

    useInterval(() => {
        dispatch(getStats(id));
    }, 30000);

    return (
        <>
            {(eventAttendeesLoading) ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className="row align-items-start">
                        <div className="col-12 col-sm-8">
                            <h3 className="pb-3 card-title m-0">{event.title}</h3>
                            <span className="mb-4 h6 badge badge-secondary badge-pill">ID Number: {event.id}</span>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className={'d-flex justify-content-end mb-3'}>
                                <Link to={contextAwareUrl('/events/' + event.id + '/edit')}
                                      className="btn btn-primary mr-3">
                                    <span className="cil-pencil btn-icon mr-2"/> Edit
                                </Link>

                                <CButton onClick={handleDeleteModal} color="danger">
                                    <span className="cil-trash btn-icon mr-2" /> Delete
                                </CButton>
                            </div>
                        </div>
                    </div>

                    <CTabs activeTab="streaming">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="streaming">
                                    Stream Control
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="content">
                                    Content
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="audience">
                                    Audience
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="messages">
                                    Messages
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent fade={false}>
                            <CTabPane data-tab="streaming">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventDetailStreaming
                                            event={event}
                                            eventAttendeeStats={eventAttendeeStats}
                                            handleDisableRmtps={handleDisableRmtps}
                                            handleEnableRmtps={handleEnableRmtps}
                                            handleVideoUpdate={handleVideoUpdate}
                                            rmtps={rmtps}
                                        />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            <CTabPane data-tab="content">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventDetailContent
                                            event={event}
                                        />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            <CTabPane data-tab="audience">
                                {/*Need to have a card around this for consistency*/}
                                <EventDetailAudience
                                    eventAttendees={eventAttendees}
                                />
                            </CTabPane>
                            <CTabPane data-tab="messages">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventDetailMessages event={event}/>
                                    </CardBody>
                                </Card>
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </>
            )}
        </>
    )
}

export default EventDetail;
