import React, {useEffect, useState} from "react";
import UserList from "../../components/users/UserList";
// import {userData} from "../../data/userData";
import api from "../../api";
import transformAudienceList from "../../transforms/audienceList";

const Audience = () => {

    const [userData, setUserData] = useState(null);

    useEffect(() => {

        api.instance.get(`/admin/audience`).then((result) => {
            setUserData(transformAudienceList(result.data));
        });

    }, []);

  return (
    <>
        {userData && <UserList usersData={userData} userList='Audience' userListTitle="Audience" />}
    </>
  )
};

export default Audience;
