import EventButton from "./EventButton";
import React from "react";
import useModal from "../../hooks/useModal";

const EventButtons = ({event, handleVideoUpdate}) => {

    const [openModal] = useModal({
        title : "Don't forget...",
        message : "You will also need to stop the stream from the source. Click OK and the stream will continue until the buffer runs out. A 'thank you for watching' message will then appear to the user.",
        cancelButton : "Cancel",
        proceedButton : "OK",
        type : "info"

    });

    const _handleVideoUpdate = () => {
        openModal(handleVideoUpdate);
    }

    return (
        <>
            <EventButton type="pending" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="open" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="online" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="offline" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="finished" event={event} handleVideoUpdate={_handleVideoUpdate}/>
            <EventButton type="on_demand" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="closed" event={event} handleVideoUpdate={handleVideoUpdate}/>
        </>
    )
};

export default EventButtons;