import api from "../api";

import {REQUEST, SUCCESS, FAILURE} from './action-type.util';

export const ACTION_TYPES = {
    FETCH_API_KEY_LIST: 'apikey/FETCH_API_KEY_LIST',
    FETCH_API_KEY: 'apikey/FETCH_API_KEY',
    CREATE_API_KEY: 'apikey/CREATE_API_KEY',
    UPDATE_API_KEY: 'apikey/UPDATE_API_KEY',
    DELETE_API_KEY: 'apikey/DELETE_API_KEY',
    RESET: 'apikey/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: {},
    updating: false,
    updateSuccess: false,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_API_KEY_LIST):
        case REQUEST(ACTION_TYPES.FETCH_API_KEY):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_API_KEY):
        case REQUEST(ACTION_TYPES.UPDATE_API_KEY):
        case REQUEST(ACTION_TYPES.DELETE_API_KEY):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_API_KEY_LIST):
        case FAILURE(ACTION_TYPES.FETCH_API_KEY):
        case FAILURE(ACTION_TYPES.CREATE_API_KEY):
        case FAILURE(ACTION_TYPES.UPDATE_API_KEY):
        case FAILURE(ACTION_TYPES.DELETE_API_KEY):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_API_KEY_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_API_KEY):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_API_KEY):
        case SUCCESS(ACTION_TYPES.UPDATE_API_KEY):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_API_KEY):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = '/system/keys';

// Actions
export const getEntities = (environmentId) => ({
    type: ACTION_TYPES.FETCH_API_KEY_LIST,
    payload: api.instance.get(`${apiUrl}?environmentId=${environmentId}&cacheBuster=${new Date().getTime()}`),
});

export const getEntity = id => ({
    type: ACTION_TYPES.FETCH_API_KEY,
    payload: api.instance.get(`${apiUrl}/${id}`),
});

export const createEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_API_KEY,
        payload: api.instance.post(`${apiUrl}`, entity),
    });
};

export const updateEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.UPDATE_API_KEY,
        payload: api.instance.put(`${apiUrl}/${entity.id}`, entity),
    });
};

export const deleteEntity = id => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.DELETE_API_KEY,
        payload: api.instance.delete(`${apiUrl}/${id}`),
    });
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
