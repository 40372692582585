/* LIBRARY IMPORTS */
import {useState, useCallback, useEffect} from 'react';

const useForm = (fieldValues) => {
    const [values, setValues] = useState(fieldValues);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formStatus, setFormStatus] = useState('dormant'); // 'sending' || 'success' || 'error'

    const handleFieldChange = useCallback(e => {
        e.persist();

        setSubmitted(false);
        setError(false);
        setErrorMessage('');

        let value = e.target.value;

        setValues(values => ({
            ...values,
            [e.target.name]: value
        }));
    }, [values]);

    useEffect(() => {
        if (formStatus === 'success' || formStatus === 'error') {
            setTimeout(() => {
                setFormStatus('dormant');
            }, 5000);
        }
    }, [formStatus]);

    return {
        values, setValues,
        loading, setLoading,
        submitted, setSubmitted,
        error, setError,
        errorMessage, setErrorMessage,
        handleFieldChange,
        formStatus, setFormStatus
    };
};

export default useForm;
