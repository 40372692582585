import React from 'react'
import ApiKeyUpdateForm from '../../components/apikeys/ApiKeyUpdateForm';
import { Link } from 'react-router-dom';
import {contextAwareUrl} from "../../helpers";

const ApiKeyUpdate = (props) => {

    return (
        <>
            <Link to={contextAwareUrl('/apikeys')} className="btn btn-dark w-fit mb-3 d-flex align-items-center">
                <span className="cil-arrow-left btn-icon mr-2"></span> Back to keys
            </Link>

            <ApiKeyUpdateForm id={props.match.params.id} />
        </>
    )
};

export default ApiKeyUpdate;
