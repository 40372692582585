import React from 'react';
import {CFormGroup, CTextarea,CLabel,CCard,CCardBody} from "@coreui/react";
import formConfig from "../../config/formConfig.json";
import {Controller} from "react-hook-form";
import TextEditor from "../../modules/TextEditor";


const TextArea = ({form,config,name}) => {

    return <CCard>
        <CCardBody>
        <CFormGroup>
            <h5 className="mb-3">{config?.label}</h5>
            <CTextarea
                className="form-control"
                name={name}
                innerRef={form.register}
                rows="3"

            />
        </CFormGroup>
        </CCardBody>
    </CCard>

}

export default TextArea;