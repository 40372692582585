import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect} from "react";
import {hydrateModalAndOpen} from "../actions/modalActions";

export const useGetModal = () => {
    return useSelector(({modal}) => modal);
};

export default ({
    cancelButton,
    message,
    proceedButton,
    title,
    type
}) => {

    const dispatch = useDispatch();

    const [proceedFn, setProceedFn] = useState(()=>()=>{});
    const [cancelFn, setCancelFn] = useState(()=>()=>{});

    const {modalActionState} = useGetModal();

    useEffect(() => {
        if (modalActionState === 'PROCEED') {

            proceedFn();
            setProceedFn(()=>()=>{});
        }
        if (modalActionState === 'CANCEL') {
            cancelFn();
            setCancelFn(()=>()=>{});
        }
    }, [modalActionState]);

    const openModal = (proceed,cancel) => {
        if (!!proceed) setProceedFn(()=>()=>{proceed()});
        if (!!cancel) setCancelFn(()=>()=>{cancel()});
        dispatch(hydrateModalAndOpen({
            type,
            title,
            proceedButton,
            message,
            cancelButton
        }))
    }


    return [openModal];


}