import React from 'react'
import EnvironmentSettingsUpdateForm from '../../components/environments/EnvironmentSettingsUpdateForm';

const EnvironmentSettings = (props) => {

    return (
        <>
            <EnvironmentSettingsUpdateForm />
        </>
    )
};

export default EnvironmentSettings;
