import React from 'react'
import AccountAdminPage from "./views/admin/AccountAdmin";
import AudienceAdminPage from "./views/admin/AudienceAdmin";

import Events from "./views/live/Events";
import EventUpdate from "./views/live/EventUpdate";
import EventAdmin from "./views/live/EventAdmin";

import EnvironmentSettings from "./views/environment/EnvironmentSettings";
import Webhooks from "./views/environment/Webhooks";
import WebhookUpdate from "./views/environment/WebhookUpdate";
import ApiKeys from "./views/environment/ApiKeys";
import ApiKeyUpdate from "./views/environment/ApiKeyUpdate";

import Dashboard from './views/admin/Dashboard';
import Accounts from './views/admin/Accounts';
import Administrators from './views/admin/Administrators';
import Audience from './views/admin/Audience';
import Sales from './views/admin/Sales';

import Uploader from './views/audio/Uploader';
import MyTracks from './views/audio/MyTracks';
import Redirect from "./views/pages/Redirect";


const routes = [
    // ADMIN
    {path: '/', exact: true, name: 'Home'},
    {path: '/dashboard', name: 'Dashboard', component: Redirect},
    {path: '/org/:org/env/:env/dashboard', name: 'Dashboard', component: Dashboard},
    {path: '/org/:org/env/:env/dashboard', name: 'Dashboard', component: Dashboard},
    // { path: '/administrators', name: 'Administrators', component: Administrators },
    // { path: '/accounts', name: 'Accounts', component: Accounts },
    // { path: '/account/:id', name: 'AccountAdmin', component: AccountAdminPage },
    {path: '/org/:org/env/:env/audience', exact: true, name: 'Audience', component: Audience},
    {path: '/org/:org/env/:env/audience/:id', exact: true, name: 'AudienceAdmin', component: AudienceAdminPage},
    // { path: '/sales', name: 'Sales', component: Sales },

    // AUDIO
    // { path: '/upload', exact: true, name: 'Upload', component: Uploader },
    // { path: '/my-tracks', exact: true, name: 'MyTracks', component: MyTracks },

    // LIVE
    {path: '/org/:org/env/:env/events', exact: true, name: 'Events', component: Events},
    {path: '/org/:org/env/:env/events/new', exact: true, name: 'EventUpdate', component: EventUpdate},
    {path: '/org/:org/env/:env/events/:id', exact: true, name: 'EventAdmin', component: EventAdmin},
    {path: '/org/:org/env/:env/events/:id/edit', exact: true, name: 'EventUpdate', component: EventUpdate},

    // environment
    {path: '/org/:org/env/:env/settings', exact: true, name: 'Settings', component: EnvironmentSettings},
    {path: '/org/:org/env/:env/webhooks', exact: true, name: 'Webhooks', component: Webhooks},
    {path: '/org/:org/env/:env/webhooks/new', exact: true, name: 'WebhookUpdate', component: WebhookUpdate},
    {path: '/org/:org/env/:env/webhooks/:id/edit', exact: true, name: 'WebhookUpdate', component: WebhookUpdate},
    {path: '/org/:org/env/:env/apikeys', exact: true, name: 'Api Keys', component: ApiKeys},
    {path: '/org/:org/env/:env/apikeys/new', exact: true, name: 'ApiKeyUpdate', component: ApiKeyUpdate},
    {path: '/org/:org/env/:env/apikeys/:id/edit', exact: true, name: 'ApiKeyUpdate', component: ApiKeyUpdate},

];

export default routes
