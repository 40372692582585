export const ACTION_TYPES = {
    SET_CURRENT_ORGANISATION: 'context/SET_CURRENT_ORGANISATION',
    SET_CURRENT_ENVIRONMENT: 'context/SET_CURRENT_ENVIRONMENT',
    RESET: 'context/RESET',
};

const initialState = {
    currentOrganisation: null,
    currentEnvironment: null,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_CURRENT_ORGANISATION:
            return {
                ...state,
                currentOrganisation: action.payload,
                currentEnvironment: action.payload.environments[0],
            };
        case ACTION_TYPES.SET_CURRENT_ENVIRONMENT:
            return {
                ...state,
                currentEnvironment: action.payload,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions
export const setCurrentOrganisation = (organisation) => ({
    type: ACTION_TYPES.SET_CURRENT_ORGANISATION,
    payload: organisation,
});

export const setCurrentEnvironment = (environment) => ({
    type: ACTION_TYPES.SET_CURRENT_ENVIRONMENT,
    payload: environment,
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
