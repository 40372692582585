import React from 'react';
import {CFormGroup, CInputGroup,CInput,CLabel,CInputGroupPrepend,CInputGroupText} from "@coreui/react";
import formConfig from "../../config/formConfig.json";

const TextField = ({config,name,form,handleChange}) => {

    return <CFormGroup>
        <CInputGroup>
            <CInputGroupPrepend>
                <label htmlFor={name}><CInputGroupText>{config.label}</CInputGroupText></label>
            </CInputGroupPrepend>
            <CInput
                type="text"
                id={name}
                name={name}
                innerRef={form.register}
                onChange={handleChange}
            />
        </CInputGroup>
    </CFormGroup>

};

TextField.defaultProps = {
    handleChange: ()=>{},
};

export default TextField;