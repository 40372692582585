import React from 'react';
import formConfig from "../../config/formConfig.json";
import {CButton, CButtonGroup, CFormGroup, CLabel} from "@coreui/react";
import {Controller} from "react-hook-form";
import TextEditor from "../../modules/TextEditor";
import {CCard, CCardHeader,CCardTitle,CCardBody} from "@coreui/react";
import FormFieldArray from "../common/FormFieldArray";
import Wysiwyg from "../common/Wysiwyg";
import TextField from "../common/TextField";


const EventUpdateFormInformation = ({defaultPerson,defaultProgramme, form, people, programme}) => {

    return <>

        <h3 className="py-2">Information</h3>

        { formConfig.config.fields?.people?.show &&

            <FormFieldArray
                data={people}
                defaultData={defaultPerson}
                form={form}
                config={formConfig.config.fields.people}
                configKey="people"
            />

        }
        { formConfig.config.fields?.programme?.show &&
            <FormFieldArray
                data={programme}
                defaultData={defaultProgramme}
                form={form}
                config={formConfig.config.fields.programme}
                configKey="programme"
            />
        }


        {
            formConfig.config.fields?.programmeNotes?.show &&
                <Wysiwyg
                    form={form}
                    config={formConfig.config.fields?.programmeNotes}
                    name="custom.programmeNotes"
                />
        }
        {
            formConfig.config.fields?.location?.show &&
             <TextField
                form={form}
                name="custom.location"
                config={formConfig.config.fields?.location}
             />
        }

    </>

}

export default EventUpdateFormInformation;