import React from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import qs from 'qs';
import useForm from "../../hooks/useForm";
import {asyncAmplifyForgotPassword, asyncAmplifyForgotPasswordSubmit} from "../../actions/auth";
import history from "../../history";
import {Col, Form, Row} from "reactstrap";
import {useDispatch} from "react-redux";

const ResetPassword = (props) => {
    const dispatch = useDispatch();

    const {
        values,
        handleFieldChange,
        loading, setLoading,
        error, setError,
        errorMessage, setErrorMessage
    } = useForm({email: qs.parse(props.location.search, { ignoreQueryPrefix: true }).email, code: '', newPassword: '', repeatPassword: ''});

    const handleSubmit = async (event) => {

        event.preventDefault();

        console.log(event);

        console.log(values);

        setLoading(true);
        setError(false);
        setErrorMessage('');

        if (values.newPassword !== values.repeatPassword) {
            setLoading(false);
            setError(true);
            setErrorMessage('Passwords do not match');
            return;
        }

        dispatch(asyncAmplifyForgotPasswordSubmit({
            email: values.email,
            code: values.code,
            password: values.newPassword
        })).then((result) => {
            setLoading(false);
        }).catch((err) => {
            console.log('err: ', err);
            setError(true);
            setErrorMessage(err.message);
            setLoading(false);
        });
    };

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="9" lg="7" xl="6">
                        <CCard className="mx-4">
                            <CCardBody className="p-4">
                                <CForm onSubmit={handleSubmit}>
                                    <h1>Reset password.</h1>
                                    <p className="text-muted">We have emailed you a reset code to verify that it's a genuine request. Please copy it from the email and paste it below, and tell us what you want your new password to be.</p>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupPrepend>
                                            <CInputGroupText>@</CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput type="email" required onChange={handleFieldChange} name="email" placeholder="Email" autoComplete="email" value={values.email || ""}/>
                                    </CInputGroup>
                                    <CInputGroup className="mb-3">
                                        <CInput type="text" required onChange={handleFieldChange} name="code" placeholder="Enter Code" autoComplete="Enter code" />
                                    </CInputGroup>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupPrepend>
                                            <CInputGroupText>
                                                <CIcon name="cil-lock-locked" />
                                            </CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput type="password" required onChange={handleFieldChange} name="newPassword" placeholder="Password" autoComplete="new-password" />
                                    </CInputGroup>
                                    <CInputGroup className="mb-4">
                                        <CInputGroupPrepend>
                                            <CInputGroupText>
                                                <CIcon name="cil-lock-locked" />
                                            </CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput type="password" required onChange={handleFieldChange} name="repeatPassword" placeholder="Repeat password" autoComplete="repeat-password" />
                                    </CInputGroup>
                                    {
                                        loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )
                                            :
                                                <CButton type="submit" color="success" block>Reset Password</CButton>
                                    }
                                </CForm>
                                {
                                    error && (
                                        <div className="alert pt-2 mt-2 alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )
                                }
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
};

export default ResetPassword
