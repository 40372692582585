import api from "../api";

import {REQUEST, SUCCESS, FAILURE} from './action-type.util';

export const ACTION_TYPES = {
    SEARCH_EVENTS: 'event/SEARCH_EVENTS',
    FETCH_EVENT_LIST: 'event/FETCH_EVENT_LIST',
    FETCH_EVENT: 'event/FETCH_EVENT',
    CREATE_EVENT: 'event/CREATE_EVENT',
    UPDATE_EVENT: 'event/UPDATE_EVENT',
    DELETE_EVENT: 'event/DELETE_EVENT',
    RESET: 'event/RESET',
};

const defaultModel = {
    title: '',
    introduction: '',
    description: '',
    metaDescription: '',
    slug: '',
    date: '',
    time: '',
    streamFileUrl: '',
    streamEnableVod: false,
    streamAutoStart: false,
    streamVodDuration: 0,
    dataStatus: 'off',
    bannerImage: null,
    thumbnailImage: null,
    programmeFile: null,
    custom: {
        people: [],
        programme: [],
    }
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultModel,
    updating: false,
    updateSuccess: false,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.SEARCH_EVENTS):
        case REQUEST(ACTION_TYPES.FETCH_EVENT_LIST):
        case REQUEST(ACTION_TYPES.FETCH_EVENT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_EVENT):
        case REQUEST(ACTION_TYPES.UPDATE_EVENT):
        case REQUEST(ACTION_TYPES.DELETE_EVENT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.SEARCH_EVENTS):
        case FAILURE(ACTION_TYPES.FETCH_EVENT_LIST):
        case FAILURE(ACTION_TYPES.FETCH_EVENT):
        case FAILURE(ACTION_TYPES.CREATE_EVENT):
        case FAILURE(ACTION_TYPES.UPDATE_EVENT):
        case FAILURE(ACTION_TYPES.DELETE_EVENT):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.SEARCH_EVENTS):
        case SUCCESS(ACTION_TYPES.FETCH_EVENT_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EVENT):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_EVENT):
        case SUCCESS(ACTION_TYPES.UPDATE_EVENT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_EVENT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = '/system/events';
const apiSearchUrl = '/_search/events';

// Actions
export const getSearchEntities = (query, page, size, sort) => ({
    type: ACTION_TYPES.SEARCH_EVENTS,
    payload: api.instance.get(`${apiSearchUrl}?query=${query}`),
});

export const getEntities = (environmentId) => ({
    type: ACTION_TYPES.FETCH_EVENT_LIST,
    payload: api.instance.get(`${apiUrl}?environmentId=${environmentId}&cacheBuster=${new Date().getTime()}`),
});

export const getEntity = id => ({
    type: ACTION_TYPES.FETCH_EVENT,
    payload: api.instance.get(`${apiUrl}/${id}`),
});

export const createEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.CREATE_EVENT,
        payload: api.instance.post(`${apiUrl}`, entity), // TODO: correct this so its not got admin
    });
};

export const updateEntity = entity => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: api.instance.put(`${apiUrl}/${entity.id}`, entity),
    });
};

export const deleteEntity = event => async dispatch => {
    return await dispatch({
        type: ACTION_TYPES.DELETE_EVENT,
        payload: api.instance.delete(`${apiUrl}/${event.id}`),
    });
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
