import {Auth} from 'aws-amplify';
import {AUTHENTICATED, LOGOUT, SET_USER} from "../types";
import api from '../../api';
import SocketIO from '../../api/sockets';

const responseStatus = (status= '', error = '', data= {}) => {
    return {
        status,
        error,
        data
    }
};

export const authenticated = () => ({
    type: AUTHENTICATED,
});

export const logout = () => ({
    type: LOGOUT
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const isAuthenticated = () => (dispatch) => {
    return Auth.currentAuthenticatedUser().then((user) => {
        api.authorize(user.signInUserSession.accessToken.jwtToken);
        SocketIO().authorize(user.signInUserSession.accessToken.jwtToken);
        dispatch(authenticated());
        return responseStatus('success')
    }).then(response => {
        return dispatch(asyncAmplifyUserInfo());
    })
        .catch((err) => {
        dispatch(logout());
        return false;
    });
};

export const asyncAmplifyUserInfo = () => (dispatch) => {
    return Auth.currentUserInfo().then((response) => {
        console.log('asyncAmplifyUserInfo response: ', response);
        dispatch(setUser(response));
    }).catch((err) => {
        console.log('asyncAmplifyUserInfo err: ', err);
        throw new Error(err.message);
    })
};

export const signIn = ({email, password}) => (dispatch) => {
    return Auth.signIn(email, password).then((response) => {
        if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // first initial login for user - they must reset password before being allowed in...
            return responseStatus('first_login', null, response);
        }

        dispatch(isAuthenticated());

        return responseStatus('success')
    }).catch((err) => {
        console.log(err);
        throw new Error(err.message);
    })
};

export const signOut = () => (dispatch) => {
    return Auth.signOut().then(() => {
        dispatch(logout());
        return responseStatus('success')
    }).catch((err) => {
        console.log(`error: ${err}`);
        throw new Error(err.message);
    });
};

export const forceChangePassword = ({user, newPassword}) => async (dispatch) => {
    return Auth.completeNewPassword(user, newPassword, null).then((response) => {
        window.location.reload(true);
    }).catch((err) => {
        console.log(err);
        throw new Error(err.message);
    });
};

export const asyncAmplifyForgotPassword = ({email}) => async (dispatch) => {

    console.log('asyncAmplifyForgotPassword: ', email);

    return Auth.forgotPassword(email).then((response) => {
        console.log('asyncAmplifyForgotPassword: ', response);
        return responseStatus('success')
    }).catch((err) => {
        console.log('asyncAmplifyForgotPassword: ', err);
        throw new Error(err.message);
    });
};

export const asyncAmplifyForgotPasswordSubmit = ({email, code, password}) => async (dispatch) => {
    return Auth.forgotPasswordSubmit(email, code, password).then((response) => {
        dispatch(signIn({email, password}))
    }).catch((err) => {
        console.log(err);
        throw new Error(err.message);
    })
};
