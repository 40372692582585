import React from 'react'
import EventDetail from '../../components/events/EventDetail';
import { Link } from 'react-router-dom';
import {contextAwareUrl} from "../../helpers";

const EventAdmin = (props) => {
    return (
    <>
        <Link to={contextAwareUrl('/events')} className="btn btn-dark w-fit mb-3 d-flex align-items-center">
            <span className="cil-arrow-left btn-icon mr-2"></span> Back to events
        </Link>

        <EventDetail id={props.match.params.id} />
    </>
    )
};

export default EventAdmin;
