import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

const VideojsPlayer = (props) => {

    const { src, live, controls, autoplay, setVideoPlayerEnded } = props;

    const videoRef = useRef(null);
    const [player, setPlayer] = useState(null);
    const [showAirPlayButton, setShowAirPlayButton] = useState(false);
    const [airPlayButtonText, setAirPlayButtonText] = useState('Stream to Airplay Device')
    const [useVideoJS, setUseVideoJS] = useState(false);

    const options = {
        autoplay : autoplay,
        fill: true,
        fluid: true,
        preload: 'auto',
        controlBar: {
            playToggle: !live,
            captionsButton: false,
            chaptersButton: false,
            subtitlesButton: false,
            remainingTimeDisplay: false,
            progressControl: {
                seekBar: !live
            },
            fullscreenToggle: true,
            playbackRateMenuButton: !live,
        },
        html5: {
            hls: {
                enableLowInitialPlaylist: true,
                smoothQualityChange: true,
                overrideNative: true,
            },
        },
    };


    useEffect(() => {

        if (window.WebKitPlaybackTargetAvailabilityEvent) {

            setUseVideoJS(false);

            /// add this here, as we want to add direct to dom eleemtn if videojs isn't being used.
            videoRef.current.addEventListener("ended", () => {
                console.log('file for video player has ended');
                setVideoPlayerEnded();
            });

        } else {
            setUseVideoJS(true);
        }

        return () => {
            if (player !== null) {
                player.dispose();
            }
        };

    }, []);

    useEffect(() => {

        if (useVideoJS) {

            const vjsPlayer = videojs(videoRef.current, {
                ...options,
                controls,
                /*muted: true,
                autoplay,*/
                sources: [src],
            });

            // used in a different useEffect, but let's use const here to avoid race condition
            setPlayer(vjsPlayer);

            vjsPlayer.on("ended", () => {
                console.log('file for video player has ended');
                setVideoPlayerEnded();
            });

        }

    },[useVideoJS, src]);


    useEffect(() => {
        if (player !== null) {
            player.src({ src });
        }
    }, [src, player]);

    return (
        <>
            <div className="videoPlayer">
                <video ref={videoRef} controls={true} autoPlay={live} className="videoPlayer__player video-js" x-webkit-airplay="allow">
                    <source src={src} />
                </video>
            </div>
        </>
    );
};

VideojsPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    controls: PropTypes.bool,
    autoplay: PropTypes.bool,
    live: PropTypes.bool
};

VideojsPlayer.defaultProps = {
    controls: true,
    autoplay: false,
    live: false
};

export default VideojsPlayer;
